/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button, Card, CardContent, CardHeader,
  Grid, PageContent, PageHeader,
} from 'common/components';
import {
  isLoading, isSuccessful, useAppState,
  useFormData, useInitial, useStateErrors
} from 'common/utils';
import { CreateBmiCalculatorWidgetAction } from 'widget/bmi-calculator/actions/create-bmi-calculator.action';
import { BmiCalculatorWidget } from 'widget/bmi-calculator/bmi-calculator.entities';
import TheForm from 'widget/bmi-calculator/components/BmiCalculatorWidgetForm';


export default function () {
  const initial = useInitial();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // store
  const [saveState, dispatch] = useAppState((store) => store.widget.save);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<BmiCalculatorWidget>(null, () => ({
      type: 'bmi-calculator',
      active: true,
      name: 'BMI Calculator',
      domain: '',
      accentColor: '#482a3f',
      ctaButtonColor: '#fe573d',
      logoUrl: '',
      headingText: '',
      descriptionMarkdown: '',
    }), []);
  useStateErrors(saveState, setFormErrors);

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(saveState)) {
        navigate(pathname.replace("/new", `/${saveState.value!.id}`), { replace: true });
      }
    }
  }, [saveState]);

  return (
    <>
      <PageHeader
        title={"New BMI Calculator Widget"}
        backButtonPath="/widgets">
        <Button
          text="Save"
          intent="success"
          onClick={() => dispatch(CreateBmiCalculatorWidgetAction(formData))}
          loading={isLoading(saveState)}
        />
      </PageHeader>
      <PageContent>
        <Grid md={2} xs={1} gap={20}>
          <Card>
            <CardHeader title={["General"]} />
            <CardContent>
              <TheForm
                value={formData}
                errors={formErrors}
                readOnly={isLoading(saveState)}
                onChange={onChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </PageContent>
    </>
  );
}
