/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ExtPhone } from "common/common.entities";

export const profileCategories = [
  'freelancer',
  'corporate-employee',
  'agency',
  'small-business-owner',
  // 'others',
] as const;

export interface Profile {
  id?: string;
  category: typeof profileCategories[number];
  firstName: string;
  lastName: string;
  dateOfBirth?: Date;
  email: string;
  website?: string;
  phone?: ExtPhone;
}