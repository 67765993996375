/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createSlice } from "@reduxjs/toolkit";

import { Profile } from "profile/profile.entities";
import { AsyncState } from "common/utils/async-state";


export const ProfileStore = createSlice({
  name: "profile",
  initialState: {
    save: {} as AsyncState<Profile>,
  },
  reducers: {
    SaveProfileState: (
      state,
      action: { payload: AsyncState<Profile> },
    ) => {
      state.save = action.payload;
    },
  },
});

export const {
  SaveProfileState,
} = ProfileStore.actions;

export const { reducer: profileReducer } = ProfileStore;

export type ProfileState = ReturnType<typeof ProfileStore.getInitialState>
