/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React, { useMemo } from "react";
import { Property } from 'csstype';

import styles from "./styles.module.sass";


export default function Column({
  className, gap, mainAxis, crossAxis, children, fill, ...rest
}: ColumnProps) {
  const classes = useMemo(() => {
    const cls = [styles.column, "flex-column", className];
    if (fill) cls.push(styles.fill);
    return cls.join(" ").trim();
  }, [className, fill])
  return (
    <div
      className={classes}
      style={{ gap, justifyContent: mainAxis, alignItems: crossAxis }}
      {...rest}>
      {children}
    </div>
  );
}

export type ColumnProps = React.ComponentPropsWithRef<"div"> & {
  gap?: number,
  mainAxis?: Property.JustifyContent;
  crossAxis?: Property.AlignItems;
  fill?: boolean;
}
