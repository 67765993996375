/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from "moment";

import { useCallback, useEffect, useMemo } from "react";
import { AiFillDashboard } from "react-icons/ai";
import {
  Bar, BarChart, CartesianGrid, Cell, Line, LineChart,
  Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts';

import { useIdentity } from "auth/auth.hooks";
import {
  BackAsyncError, Backout, Button, Card, CardContent,
  CardHeader, Grid, PageContentLoader
} from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { AsyncStateBuilder, isEmpty, useAppState } from "common/utils";
import { useGetAllDomains } from "domain/domain.hooks";
import { GetWidgetViewStatsBaseReport } from "widget/common/actions/get-widget-view-stats-base-report.action";
import { WidgetViewStatsBaseReport } from "widget/widget.entities";
import { useGetAllWidgets } from "widget/widget.hooks";
import styles from './styles.module.scss';


export default function WidgetViewStatsBaseReportPage() {
  const identity = useIdentity();
  const [getBaseReportState, dispatch] = useAppState(store => store.widget.viewStats.getBaseReport);
  const { domains } = useGetAllDomains();
  const { widgets } = useGetAllWidgets();

  const getBaseReport = useCallback(() => {
    dispatch(GetWidgetViewStatsBaseReport());
  }, []);

  useEffect(() => {
    if (isEmpty(getBaseReportState))
      getBaseReport();
  }, [getBaseReportState])

  const currentPeriodData = useMemo(() => {
    const data: any[] = [];
    if (getBaseReportState.value) {
      const value = getBaseReportState.value!;
      const diff = moment(value.currentPeriodEndsAt)
        .diff(value.currentPeriodStartsAt, 'days');
      for (let i = 0; i < diff; i++) {
        const day = moment(value.currentPeriodStartsAt).add(i, 'day').format('MM/DD');
        data.push({
          name: day,
          views: value.viewsCurrentPeriod[day],
        })
      }
    }
    return data;
  }, [getBaseReportState.value]);

  const todayData = useMemo(() => {
    const data: any[] = [];
    if (getBaseReportState.value) {
      const value = getBaseReportState.value!;
      for (let h = 0; h < 24; h++) {
        const hour = `${h.toString().padStart(2, '0')}:00`
        data.push({
          name: hour,
          views: value.viewsToday[hour],
        })
      }
    }
    return data;
  }, [getBaseReportState.value]);

  const topDomainsData = useMemo(() => {
    const data: any[] = [];
    if (getBaseReportState.value) {
      const value = getBaseReportState.value!;
      for (const domain of Object.keys(value.topDomains)) {
        const name = domains.find(d => d.id === domain)?.name;
        data.push({
          name: name || domain,
          views: value.topDomains[domain],
        })
      }
    }
    return data;
  }, [getBaseReportState.value, domains]);

  const topWidgetsData = useMemo(() => {
    const data: any[] = [];
    if (getBaseReportState.value) {
      const value = getBaseReportState.value!;
      for (const widget of Object.keys(value.topWidgets)) {
        const name = widgets.find(d => d.id === widget)?.name;
        data.push({
          name: name || widget,
          views: value.topWidgets[widget],
        })
      }
    }
    return data;
  }, [getBaseReportState.value, widgets]);

  const topReferersData = useMemo(() => {
    const data: any[] = [];
    if (getBaseReportState.value) {
      const value = getBaseReportState.value!;
      for (const referer of Object.keys(value.topReferers)) {
        data.push({
          name: referer,
          views: value.topReferers[referer],
        })
      }
    }
    return data;
  }, [getBaseReportState.value]);

  const totalViewsData = useMemo(() => {
    const data: any[] = [];
    if (getBaseReportState.value) {
      const value = getBaseReportState.value!;
      data.push({
        name: 'Current Period',
        views: value.totalViews,
        color: '#FFBB28',
      })
    }
    if (identity?.subscription?.quota?.totalWidgetViews) {
      data.push({
        name: 'Total Views',
        views: identity.subscription.quota.totalWidgetViews,
        color: '#eee',
      })
    }
    return data;
  }, [getBaseReportState.value]);

  return (
    <>
      <PageHeader
        icon={<AiFillDashboard size={23} style={{ marginRight: 1, marginTop: -1 }} />}
        title={['Dashboard']} >
        <Button
          minimal
          icon="refresh"
          onClick={getBaseReport}
        />
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder<WidgetViewStatsBaseReport>
          state={getBaseReportState}
          onLoading={() => <PageContentLoader md={2} sm={2} xs={1} gap={20} />}
          onFailed={(error) => <BackAsyncError error={error} onTryAgain={getBaseReport} />}
          onValueEmpty={() => (
            <div style={{ marginTop: 150 }}>
              <Backout
                icon={<AiFillDashboard style={{ marginTop: -3, marginLeft: -2 }} />}
                title="No Stats to Display Yet">
              </Backout>
            </div>
          )}
          onValueNotEmpty={(report) =>
            <Grid md={2} xs={1} gap={20}>
              <Card>
                <CardHeader title={["Widget Views Today"]} />
                <CardContent className={styles.chartCardContent}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={todayData} >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis width={25} tickCount={4}/>
                      <Tooltip />
                      <Line type="monotone" dataKey="views" stroke="#f9bf3b" strokeWidth={2} />
                    </LineChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={["Widget Views in Current Period"]} />
                <CardContent className={styles.chartCardContent}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={currentPeriodData} >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis width={25} tickCount={4}/>
                      <Tooltip />
                      <Line type="monotone" dataKey="views" stroke="#f9bf3b" strokeWidth={2} />
                    </LineChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={["Top Domains"]} />
                <CardContent className={styles.chartCardContent}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={topDomainsData} >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis width={25} tickCount={4}/>
                      <Bar dataKey="views" fill="#42a5f5" barSize={20} />
                      <Tooltip cursor={{ fill: '#42a5f522' }} />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={["Top Widgets"]} />
                <CardContent className={styles.chartCardContent}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={topWidgetsData} >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis width={25} tickCount={4}/>
                      <Bar dataKey="views" fill="#4caf50" barSize={20} />
                      <Tooltip cursor={{ fill: '#4caf5022' }} />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={["Top Referers"]} />
                <CardContent className={styles.chartCardContent}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={topReferersData} >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis width={25} tickCount={4}/>
                      <Bar dataKey="views" fill="#e57373" barSize={20} />
                      <Tooltip cursor={{ fill: '#e5737322' }} />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={["Total Widget Views"]} />
                <CardContent className={styles.chartCardContent}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        dataKey="views"
                        innerRadius={60}
                        outerRadius={100}
                        fill="#82ca9d"
                        data={totalViewsData}>
                        {totalViewsData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
          }
        />
      </PageContent>
    </>
  );
}
