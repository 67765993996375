/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Property } from 'csstype';

import styles from "./styles.module.sass";


export default function Row({
  className, gap, mainAxis, crossAxis, children, 
  innerRef, ...rest
}: RowProps) {
  return (
    <div
      ref={innerRef}
      className={[styles.row, "flex-row", className].join(" ").trim()}
      style={{ gap, justifyContent: mainAxis, alignItems: crossAxis }}
      {...rest}>
      {children}
    </div>
  );
}
export type RowProps = React.ComponentPropsWithRef<"div"> & {
  gap?: number,
  mainAxis?: Property.JustifyContent;
  crossAxis?: Property.AlignItems;
  innerRef?: React.LegacyRef<HTMLDivElement>,
}