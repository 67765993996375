/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { GetAllBmiSubmissions } from "bmi-submissions/actions/get-all-submissions.action";
import { BmiSubmission } from "bmi-submissions/bmi-submission.entities";
import { isEmpty, useAppState } from "common/utils";


export function useGetAllBmiSubmissions<T extends BmiSubmission = BmiSubmission>(opts: {
  auto?: boolean,
  find?: (value: BmiSubmission, index: number, array: BmiSubmission[]) => boolean,
  filter?: (value: BmiSubmission, index: number, array: BmiSubmission[]) => boolean,
} = {}) {
  const [getAllBmiSubmissionsState, dispatch] = useAppState((state) => state.bmiSubmissions.getAll);

  const getAllBmiSubmissions = useCallback(() => {
    dispatch(GetAllBmiSubmissions());
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(getAllBmiSubmissionsState))
      getAllBmiSubmissions();
  }, [opts.auto, getAllBmiSubmissionsState])

  const submissions = useMemo(() =>
    ((opts.filter == null
      ? getAllBmiSubmissionsState.value
      : getAllBmiSubmissionsState.value?.filter(opts.filter)) || []) as T[],
    [getAllBmiSubmissionsState, opts.filter]);

  const submission = useMemo(() =>
    opts.find == null
      ? null
      : getAllBmiSubmissionsState.value?.find(opts.find) as T,
    [getAllBmiSubmissionsState, opts.find]);

  return { getAllBmiSubmissionsState, getAllBmiSubmissions, submissions, submission };
}