/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ColorPickerField, DropdownField } from "common/components";
import Form, { EntityFormProps } from "common/components/form/Form";
import NumericInputField from "common/components/form/Numeric";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { AdaWidgetButtonStyles, adaWidgetButtonPositions } from "widget/ada/ada-widget.entities";
import styles from "./styles.module.sass";


export default function AdaWidgetButtonStylesForm({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: AdaWidgetButtonStylesFormProps) {
  const { t } = useTranslation();
  const classes = useMemo(() => [styles.adaWidgetStylesForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  const positionOptions = useMemo(() => adaWidgetButtonPositions.map(i => ({
    id: i,
    text: t(`AdaWidgetPositions.${i}`),
    value: i,
  })), []);

  return (
    <Form
      className={classes}
      {...rest}>
      <DropdownField
        nullable
        name={fieldName("position")}
        label={["Position"]}
        options={positionOptions}
        value={value?.position}
        error={errors?.position}
        readOnly={readOnly}
        hidden={isHidden("position")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("color")}
        label="Color"
        value={value?.color}
        error={errors?.color}
        readOnly={readOnly}
        hidden={isHidden("color")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("hoverColor")}
        label="Hover Color"
        value={value?.hoverColor}
        error={errors?.hoverColor}
        readOnly={readOnly}
        hidden={isHidden("hoverColor")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("bgColor")}
        label="Background Color"
        value={value?.bgColor}
        error={errors?.bgColor}
        readOnly={readOnly}
        hidden={isHidden("bgColor")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("bgHoverColor")}
        label="Background Hover Color"
        value={value?.bgHoverColor}
        error={errors?.bgHoverColor}
        readOnly={readOnly}
        hidden={isHidden("bgHoverColor")}
        onChange={onChange}
      />
      <NumericInputField
        name={fieldName("margin")}
        label="Margin"
        value={value?.margin}
        error={errors?.margin}
        readOnly={readOnly}
        hidden={isHidden("margin")}
        onChange={onChange}
      />
      <NumericInputField
        name={fieldName("padding")}
        label="Padding"
        value={value?.padding}
        error={errors?.padding}
        readOnly={readOnly}
        hidden={isHidden("padding")}
        onChange={onChange}
      />
      <NumericInputField
        name={fieldName("radius")}
        label="Radius"
        value={value?.radius}
        error={errors?.radius}
        readOnly={readOnly}
        hidden={isHidden("radius")}
        onChange={onChange}
      />
      <NumericInputField
        name={fieldName("size")}
        label="Size"
        value={value?.size}
        error={errors?.size}
        readOnly={readOnly}
        hidden={isHidden("size")}
        onChange={onChange}
      />
      <NumericInputField
        name={fieldName("apperanceDelay")}
        label="Apperance Delay"
        value={value?.apperanceDelay}
        error={errors?.apperanceDelay}
        readOnly={readOnly}
        hidden={isHidden("apperanceDelay")}
        onChange={onChange}
      />
      {children}
    </Form>
  );
}

export type AdaWidgetButtonStylesFormProps = EntityFormProps<AdaWidgetButtonStyles> & {
};