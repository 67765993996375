/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { isEmpty, useAppState } from "common/utils";
import { GetAllDomains } from "domain/actions/get-all-domains.action";
import { Domain } from "domain/domain.entities";


export function useGetAllDomains<T extends Domain = Domain>(opts: {
  auto?: boolean,
  find?: (value: Domain, index: number, array: Domain[]) => boolean,
  filter?: (value: Domain, index: number, array: Domain[]) => boolean,
} = {}) {
  const [getAllDomainsState, dispatch] = useAppState((state) => state.domain.getAll);

  const getAllDomains = useCallback(() => {
    dispatch(GetAllDomains());
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(getAllDomainsState))
      getAllDomains();
  }, [opts.auto, getAllDomainsState])

  const domains = useMemo(() =>
    ((opts.filter == null
      ? getAllDomainsState.value
      : getAllDomainsState.value?.filter(opts.filter)) || []) as T[],
    [getAllDomainsState, opts.filter]);

  const domain = useMemo(() =>
    opts.find == null
      ? null
      : getAllDomainsState.value?.find(opts.find) as T,
    [getAllDomainsState, opts.find]);

  return { getAllDomainsState, getAllDomains, domains, domain };
}