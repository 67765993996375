/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { HTMLSelectProps } from "@blueprintjs/core";
import { useMemo } from "react";
import { OnChangeEventHandler } from "common/utils";

import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.sass";


export default function DropdownField<T>({
  className, label, hidden, disabled, error, nullable = false,
  name, value, readOnly, options, onChange, actions, children, ...rest
}: DropdownFieldProps<T>) {
  const classes = useMemo(() => {
    const items = [styles.dropdownField, className];
    if (readOnly) items.push(styles.readOnly);
    return items;
  }, [className, readOnly]);

  const selected = useMemo(() => options.find(o => o.id === value),
    [value, options]);

  const elements = useMemo(() => {
    const opts = new Array<JSX.Element>();
    if (nullable)
      opts.push(<option key={'option_null'} value={null as any}></option>);
    options.forEach((o, index) =>
      opts.push(<option key={`option_${index}`} value={o.id}>{o.text}</option>));
    return opts;
  }, [options, nullable])

  return (
    <FormField
      className={classes.join(" ")}
      label={label}
      disabled={disabled}
      hidden={hidden}
      error={error}
      actions={actions}>
      <select
        name={name}
        value={selected?.id}
        disabled={readOnly || disabled}
        intent={error ? "danger" : undefined}
        onChange={({ target: { name, value } }) => {
          if (onChange) {
            const item = options.find(o => o.id === value);
            if (item || nullable)
              onChange({ target: { name, value: item?.value || null as any } });
          }
        }}
        {...rest}>
        {elements}
      </select>
      {children}
    </FormField>
  );
}

export type DropdownOption<T> = {
  id: string;
  text: string;
  value: T;
};

export type DropdownFieldProps<T> = FormFieldProps &
  Omit<HTMLSelectProps, "onChange" | "options" | "value"> & {
    name?: string,
    value?: T,
    options: Array<DropdownOption<T>>,
    readOnly?: boolean,
  } & ({
    nullable?: true,
    onChange?: OnChangeEventHandler<T | null>,
  } | {
    nullable?: false | null | undefined,
    onChange?: OnChangeEventHandler<T>,
  })