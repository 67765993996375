/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import * as Blueprint from "@blueprintjs/core";
import { useMemo } from "react";

import styles from "./styles.module.sass";


export default function Card({
  className, animating = false, children, ...rest
}: CardProps) {
  // consts
  const classes = useMemo(() => {
    const classes = [styles.card, className];
    if (animating) classes.push(styles.animating);
    return classes;
  }, [className]);

  return (
    <Blueprint.Card className={classes.join(" ")} {...rest}>
      {children}
    </Blueprint.Card>
  );
}

export type CardProps = Omit<Blueprint.CardProps, "title"> & {
  animating?: boolean,
}