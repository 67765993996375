/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React from "react";
import { Route, Routes } from "react-router-dom";

import AuthGuard from "auth/guards/Auth";
import WorkspaceLayout from "common/layouts/Workspace";
import Page404 from "common/pages/404";
import DomainPage from "domain/pages/DomainPage";
import DomainsPage from "domain/pages/DomainsPage";
import ProfileGuard from "profile/guards/ProfileGuard";
import ProfilePage from "profile/pages/ProfilePage";
import SubscriptionPage from "subscription/pages/SubscriptionPage";
import CreateAdaWidgetPage from "widget/ada/pages/CreateAdaWidgetPage";
import EditAdaWidgetPage from "widget/ada/pages/EditAdaWidgetPage";
import WidgetsPage from "widget/common/pages/WidgetsPage";
import WidgetViewStatsBaseReportPage from "widget/common/pages/WidgetViewStatsBaseReport";
import CreateBmiCalculatorWidgetPage from "widget/bmi-calculator/pages/CreateBmiCalculatorWidgetPage";
import EditBmiCalculatorWidgetPage from "widget/bmi-calculator/pages/EditBmiCalculatorWidgetPage";
import BmiSubmissionsPage from "bmi-submissions/pages/BmiSubmissionsPage";
import BmiSubmissionPage from "bmi-submissions/pages/BmiSubmissionPage";

const CreateRoiCalculatorPage = React.lazy(() => import("widget/roi-calculator/pages/CreateRoiCalculatorPage"));
const EditRoiCalculatorPage = React.lazy(() => import("widget/roi-calculator/pages/EditRoiCalculatorPage"));
const CreateAuditReportPage = React.lazy(() => import("widget/audit-report/pages/CreateAuditReportPage"));
const EditAuditReportPage = React.lazy(() => import("widget/audit-report/pages/EditAuditReportPage"));
const OnLogin = React.lazy(() => import("auth/pages/OnLogin"));
const PincodePage = React.lazy(() => import("auth/pages/Pincode"));


export default function Router() {
  return (
    <Routes>
      <Route path="/on-login" element={<OnLogin />} />
      <Route element={<AuthGuard />}>
        <Route element={<ProfileGuard layout={(child) => <WorkspaceLayout>{child}</WorkspaceLayout>} />}>
          <Route path="/" element={<WidgetViewStatsBaseReportPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/pincode" element={<PincodePage />} />
          <Route path="/widgets" element={<WidgetsPage />} />
          <Route path="/widgets/ada/new" element={<CreateAdaWidgetPage />} />
          <Route path="/widgets/ada/:id" element={<EditAdaWidgetPage />} />
          <Route path="/widgets/roi-calculator/new" element={<CreateRoiCalculatorPage />} />
          <Route path="/widgets/roi-calculator/:id" element={<EditRoiCalculatorPage />} />
          <Route path="/widgets/audit-report/new" element={<CreateAuditReportPage />} />
          <Route path="/widgets/audit-report/:id" element={<EditAuditReportPage />} />
          <Route path="/widgets/bmi-calculator/new" element={<CreateBmiCalculatorWidgetPage />} />
          <Route path="/widgets/bmi-calculator/:id" element={<EditBmiCalculatorWidgetPage />} />
          <Route path="/bmi-submissions" element={<BmiSubmissionsPage />} />
          <Route path="/bmi-submissions/:id" element={<BmiSubmissionPage />} />
          <Route path="/domains" element={<DomainsPage />} />
          <Route path="/domains/:id" element={<DomainPage />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
        </Route>
      </Route>
      <Route element={<AuthGuard role='admin' layout={(child) => <WorkspaceLayout>{child}</WorkspaceLayout>} />}>
        <Route path="/users" element={<div>TODO</div>} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
