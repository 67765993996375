/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { BmiCalculatorWidget } from "widget/bmi-calculator/bmi-calculator.entities";
import { SaveWidgetState } from "widget/widget.store";


export function UpdateBmiCalculator(id: string, changes: Partial<BmiCalculatorWidget>): AsyncAction {
  return async (dispatch, _getStore, api) => {
    dispatch(SaveWidgetState(loading()));
    try {
      const { value } = await api.put(`/widgets/bmi-calculator/${id}`, { data: changes })
      dispatch(SaveWidgetState(success(value)));
    } catch (error) {
      dispatch(SaveWidgetState(failed(error)));
    }
  };
}
