/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Alert, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  BackActivityIndicator, BackAsyncError,
  Button, Card, CardContent, CardHeader,
  Grid, PageContent, PageHeader, PageNotFound,
  Toast
} from 'common/components';
import {
  AsyncStateBuilder, isLoading, isSuccessful, useAppState,
  useFormData, useInitial, useStateErrors,
} from 'common/utils';
import { CreateDomain } from 'domain/actions/create-domain.action';
import { DeleteDomain } from 'domain/actions/delete-domain.action';
import { UpdateDomain } from 'domain/actions/update-domain.action';
import DomainForm from 'domain/components/DomainForm';
import { Domain } from 'domain/domain.entities';
import { useGetAllDomains } from 'domain/domain.hooks';
import SubscriptionPlansDialog from 'subscription/components/SubscriptionPlansDialog';
import ManageSubscriptionDialog from 'subscription/components/ManageSubscriptionDialog';

export default function DomainPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const initial = useInitial();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  // store
  const { getAllDomainsState, getAllDomains, domain } = useGetAllDomains({
    find: domain => domain.id === id,
  });
  const [saveState, dispatch] = useAppState((store) => store.domain.save);
  const [deleteState] = useAppState((store) => store.domain.delete);

  // state
  const [isConfirmingDeletion, confirmDeletion] = useState(false);
  const [isSubscriptionPlansDialogVisible, setSubscriptionPlansDialogVisible] = useState(false);
  const [isManageSubscriptionDialogVisible, setManageSubscriptionDialogVisible] = useState(false);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<Domain>(domain, () => ({
      active: true,
      name: '',
      http: true,
      https: true,
      subdomains: true,
    }), [domain]);
  useStateErrors(saveState, setFormErrors);

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(saveState)) {
        if (id === "new") {
          navigate(pathname.replace("/new", `/${saveState.value!.entity.id}`), { replace: true });
        }
        if (saveState.value?.status === 'no-subscription') {
          setTimeout(() => setSubscriptionPlansDialogVisible(true), 800);
        }
        if (saveState.value?.status === 'upgrade-subscription') {
          setTimeout(() => setManageSubscriptionDialogVisible(true), 800);
        }
      }
    }
  }, [saveState]);
  useEffect(() => {
    if (!initial) {
      if (deleteState.value) {
        Toast.showSuccess({ message: "Domain disconnected" });
        navigate('/domains');
      } else if (deleteState.error) {
        Toast.showAsyncError(t, deleteState.error);
      }
    }
  }, [deleteState]);

  return (
    <>
      <PageHeader
        title={domain?.name || "New Domain"}
        backButtonPath={state?.backpath || "/domains"}>
        <Button
          text="Connect"
          intent="success"
          onClick={() => dispatch(CreateDomain(formData))}
          hidden={id !== 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Button
          text="Update"
          intent="success"
          onClick={() => dispatch(UpdateDomain(domain!, changes))}
          hidden={id === 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon="disable"
                text="Disconnect"
                intent='danger'
                hidden={domain == null}
                disabled={domain == null}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button minimal rightIcon="menu" />
        </Popover2>
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder state={getAllDomainsState}
          onLoading={() => <BackActivityIndicator tall />}
          onFailed={(error) => <BackAsyncError error={error} onTryAgain={getAllDomains} />}
          onValue={() => {
            if (!domain && id !== "new")
              return <PageNotFound />
            return (
              <Grid md={2} xs={1} gap={20}>
                <Card>
                  <CardContent>
                    <DomainForm
                      value={formData}
                      errors={formErrors}
                      readOnly={isLoading(saveState)}
                      onChange={onChange}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )
          }}
        />
        <Alert
          isOpen={isConfirmingDeletion}
          confirmButtonText='Disconnect'
          cancelButtonText='Nope'
          icon="disable"
          intent="danger"
          loading={isLoading(deleteState)}
          onCancel={() => confirmDeletion(false)}
          onConfirm={() => dispatch(DeleteDomain(id!))}>
          <p>Disconnect this domain?</p>
        </Alert>
        <SubscriptionPlansDialog
          title="To activate this domain you need a Subscription"
          isOpen={isSubscriptionPlansDialogVisible}
          onClose={() => setSubscriptionPlansDialogVisible(false)} />
        <ManageSubscriptionDialog
          title="My Subscription"
          isOpen={isManageSubscriptionDialogVisible}
          onClose={() => setManageSubscriptionDialogVisible(false)}
          widgetProps={{
            buttonText: "Upgrade Subscription"
          }}>
            <span className='error'>* to activate this domain you need to upgrade your subscription</span>
          </ManageSubscriptionDialog>
      </PageContent>
    </>
  );
}
