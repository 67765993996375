/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Slider, SliderProps } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { OnChangeEventHandler } from "common/utils";

import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.sass";


export default function SliderField({
  className, label, hidden, disabled, error, name,
  readOnly, value, onChange, valueAspect = 1, ...rest
}: SliderFieldProps) {
  const classes = [styles.sliderField, className];
  if (disabled || readOnly)
    classes.push(styles.muted)

  const [val, setVal] = useState(0);
  useEffect(() => {
    if (value != null)
      setVal(value / valueAspect);
  }, [value])

  return (
    <FormField
      borderless
      className={classes.join(" ")}
      label={label}
      disabled={disabled}
      hidden={hidden}
      error={error}
    >
      <Slider
        disabled={disabled}
        showTrackFill={!disabled && !readOnly}
        intent={error ? "danger" : undefined}
        value={val}
        onChange={readOnly ? undefined : setVal}
        onRelease={readOnly ? undefined : (value) => {
          onChange({ target: { name, value: value * valueAspect } });
        }}
        {...rest}
      />
    </FormField>
  );
}



export type SliderFieldProps = FormFieldProps & Omit<SliderProps, "onChange"> & {
  onChange: OnChangeEventHandler<number>
  valueAspect?: number,
  name: string,
  readOnly?: boolean,
}