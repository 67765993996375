/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";

import { ColorPickerField } from "common/components";
import Form, { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { AdaWidgetTheme } from "widget/ada/ada-widget.entities";
import styles from "./styles.module.sass";


export default function AdaWidgetThemeForm({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: AdaWidgetThemeFormProps) {
  const classes = useMemo(() => [styles.adaWidgetThemeForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  return (
    <Form
      className={classes}
      {...rest}>
      <ColorPickerField
        name={fieldName("color")}
        label={["Color"]}
        value={value?.color}
        error={errors?.color}
        readOnly={readOnly}
        hidden={isHidden("color")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("textColor")}
        label={["Text Color"]}
        value={value?.textColor}
        error={errors?.textColor}
        readOnly={readOnly}
        hidden={isHidden("textColor")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("bgColor")}
        label={["Background Color"]}
        value={value?.bgColor}
        error={errors?.bgColor}
        readOnly={readOnly}
        hidden={isHidden("bgColor")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("keyboardBgColor")}
        label={["Keyboard Backgound Color"]}
        value={value?.keyboardBgColor}
        error={errors?.keyboardBgColor}
        readOnly={readOnly}
        hidden={isHidden("keyboardBgColor")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("keyboardKeyColor")}
        label={["Keyboard Key Color"]}
        value={value?.keyboardKeyColor}
        error={errors?.keyboardKeyColor}
        readOnly={readOnly}
        hidden={isHidden("keyboardKeyColor")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("keyboardKeyBgColor")}
        label={["Keyboard Key Background Color"]}
        value={value?.keyboardKeyBgColor}
        error={errors?.keyboardKeyBgColor}
        readOnly={readOnly}
        hidden={isHidden("keyboardKeyBgColor")}
        onChange={onChange}
      />
      {children}
    </Form>
  );
}

export type AdaWidgetThemeFormProps = EntityFormProps<AdaWidgetTheme> & {
};