/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from "react";

import styles from "./styles.module.css";


export default function Grid({
  className, children, gap = 0, md = 3, sm, xs, ...rest
}: GridProps) {
  // consts
  const classes = [styles.grid, className, `md-${md}`];
  if (sm != null) classes.push(`sm-${sm}`)
  if (xs != null) classes.push(`xs-${xs}`)
  return (
    <div
      className={classes.join(" ")}
      style={{ gap }}
      {...rest}>
      {children}
    </div>
  );
}

export type GridProps = React.ComponentPropsWithRef<"div"> & {
  gap?: number,
  md: 1 | 2 | 3 | 4,
  sm?: 1 | 2 | 3,
  xs?: 1 | 2,
}