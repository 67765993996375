/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiBodyScanFill } from "react-icons/ri";

import { Button } from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import BmiSubmissionsGrid from "bmi-submissions/containers/BmiSubmissionGrid";
import { useGetAllBmiSubmissions } from "bmi-submissions/bmi-submission.hooks";


export default function BmiSubmissionsPage() {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const { getAllBmiSubmissions } = useGetAllBmiSubmissions();
  return (
    <>
      <PageHeader
        icon={<RiBodyScanFill size={20} style={{ marginRight: 2, marginTop: -.5 }} />}
        title={['BMI Submissions']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          minimal
          icon="refresh"
          onClick={getAllBmiSubmissions}
        />
      </PageHeader>
      <PageContent>
        <BmiSubmissionsGrid filterQuery={searchQuery} />
      </PageContent>
    </>
  );
}
