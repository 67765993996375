/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createContext } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { AppState } from "app.store";
import { Backout, Card, CardContent, CardHeader } from "common/components";
import { Identity } from "auth/auth.entities";
import { isNotSuccessful } from "common/utils";
import { LoginForm } from "../../containers";


export const IdentityContext = createContext<Identity | null>(null);

export default function AuthGuard({ role, layout }: AuthGuardProps) {
  const loginState = useSelector((state: AppState) => state.auth.login);

  let child: JSX.Element;
  if (isNotSuccessful(loginState)) {
    child = (
      <div className="fullscreenGuard">
        <Card elevation={2}>
          <CardHeader title={["Login"]} />
          <CardContent>
            <LoginForm />
          </CardContent>
        </Card>
      </div>
    );
  } else if (role && loginState.value!.user.role !== role) {
    child = (
      <div className="fullscreenGuard">
        <Backout
          icon="lock"
          title={["AccessDenied"]}
          message={["YouDontHavePermission"]} />
      </div>
    );
  } else {
    child = (
      <IdentityContext.Provider value={loginState.value!}>
        <Outlet />
      </IdentityContext.Provider>
    );
  }

  return layout == null ? child : layout(child);
}

export interface AuthGuardProps {
  role?: string;
  layout?: (child: JSX.Element | null) => JSX.Element,
}