/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Alignment, Menu, MenuItem, Navbar, Position } from "@blueprintjs/core";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PiPasswordBold } from 'react-icons/pi';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppState } from "app.store";
import { LoginState } from "auth/auth.store";
import { Avatar } from "common/components";
import styles from "./styles.module.sass";


export default function Topbar({ className, menuButton }: TopbarProps) {
  // uses
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  // consts
  const classes = [styles.topbar, className].join(" ");

  // states
  const [isEstMenuOpen, openEstMenu] = useState(false);

  // store
  const loginState = useSelector((state: AppState) => state.auth.login);
  const currentProfile = useMemo(() => loginState.value?.profile, [loginState]);

  return (
    <Navbar className={classes}>
      <Navbar.Group align={Alignment.LEFT}>
        {menuButton}
        <Navbar.Heading>
          <Tooltip2
            content={t("AppVersion", { version: process.env.REACT_APP_VERSION })}
            position="right"
            hoverOpenDelay={3000}>
            <img
              className="logo"
              src="/static/images/logo512.png"
              alt="logo"
              onClick={() => navigate("/")}
            />
          </Tooltip2>
        </Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        {currentProfile && (
          <Popover2
            position={Position.BOTTOM_RIGHT}
            isOpen={isEstMenuOpen}
            onInteraction={openEstMenu}
            content={
              <Menu>
                <MenuItem
                  icon="user"
                  text={["Profile"]}
                  onClick={(e) => {
                    openEstMenu(false);
                    navigate('/profile');
                  }}
                />
                <MenuItem
                  icon={<PiPasswordBold />}
                  text={["Pincode"]}
                  onClick={(e) => {
                    openEstMenu(false);
                    navigate('/pincode');
                  }}
                />
                <MenuItem
                  icon="log-out"
                  text="Logout"
                  onClick={() => {
                    dispatch(LoginState({}));
                    navigate('/');
                  }}
                />
              </Menu>
            }>
            <Avatar
              size={32}
              name={currentProfile.firstName + ' ' + currentProfile.lastName}
            />
          </Popover2>
        )}
      </Navbar.Group>
    </Navbar>
  );
}

export type TopbarProps = {
  className?: string,
  menuButton?: JSX.Element,
}