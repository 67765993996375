/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { configureStore } from "@reduxjs/toolkit";
import _ from "lodash";

import { authReducer, AuthState } from "auth/auth.store";
import { ApiClient } from "common/utils";
import { domainReducer, DomainState } from "domain/domain.store";
import { mediaReducer, MediaState } from "media/media.store";
import { profileReducer, ProfileState } from "profile/profile.store";
import { subscriptionReducer, SubscriptionState } from "subscription/subscription.store";
import { widgetReducer, WidgetState } from "widget/widget.store";
import { bmiSubmissionReducer, BmiSubmissionState } from "bmi-submissions/bmi-submission.store";


export interface AppState {
  auth: AuthState,
  profile: ProfileState,
  widget: WidgetState,
  media: MediaState,
  domain: DomainState,
  subscription: SubscriptionState,
  bmiSubmissions: BmiSubmissionState,
  prev?: AppState,
}

export default function createStore(api: ApiClient) {
  const store = configureStore<AppState>({
    reducer: (state, action) => ({
      auth: authReducer(state?.auth, action),
      media: mediaReducer(state?.media, action),
      profile: profileReducer(state?.profile, action),
      widget: widgetReducer(state?.widget, action),
      domain: domainReducer(state?.domain, action),
      subscription: subscriptionReducer(state?.subscription, action),
      bmiSubmissions: bmiSubmissionReducer(state?.bmiSubmissions, action),
      prev: _.omit(state, "prev"),
    }),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      thunk: {
        extraArgument: api,
      },
      serializableCheck: false,
    }) as any,
  });
  api.attachStore(store);
  return store;
}
