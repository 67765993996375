/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { NonIdealState, NonIdealStateProps } from "@blueprintjs/core";

import styles from "./styles.module.sass";
import { useMemo } from 'react';


export default function Backout({
  className, title, message, children, tall, ...rest
}: BackoutProps) {
  // uses
  const { t } = useTranslation()

  const classes = useMemo(() => {
    const classes = [styles.backout, className];
    if (tall) classes.push(styles.tall);
    return classes.join(' ').trim();
  }, [className, tall]);

  // consts
  const titleText = _.isArray(title) ? t(title[0], title[2], title[1]) : title;
  const msgText = _.isArray(message) ? t(message[0], message[2], message[1]) : message;

  return (
    <NonIdealState
      className={classes}
      title={titleText}
      description={msgText}
      {...rest}>
      {children}
    </NonIdealState>
  );
}

export type BackoutProps = Omit<NonIdealStateProps, "title" | "description"> & {
  title?: string | [string, object?, string?],
  message?: string | [string, object?, string?],
  tall?: boolean;
}