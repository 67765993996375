/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";

import { ColorPickerField } from "common/components";
import Form, { EntityFormProps } from "common/components/form/Form";
import NumericInputField from "common/components/form/Numeric";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { AdaWidgetMagnifierStyles } from "widget/ada/ada-widget.entities";
import styles from "./styles.module.sass";


export default function AdaWidgetMagnifierStylesForm({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: AdaWidgetMagnifierStylesFormProps) {
  const classes = useMemo(() => [styles.adaWidgetStylesForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  return (
    <Form
      className={classes}
      {...rest}>
      <ColorPickerField
        name={fieldName("color")}
        label="Color"
        value={value?.color}
        error={errors?.color}
        readOnly={readOnly}
        hidden={isHidden("color")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("bgColor")}
        label="Background Color"
        value={value?.bgColor}
        error={errors?.bgColor}
        readOnly={readOnly}
        hidden={isHidden("bgColor")}
        onChange={onChange}
      />
      <NumericInputField
        name={fieldName("fontSize")}
        label="Font Size"
        value={value?.fontSize}
        error={errors?.fontSize}
        readOnly={readOnly}
        hidden={isHidden("fontSize")}
        onChange={onChange}
      />
      {children}
    </Form>
  );
}

export type AdaWidgetMagnifierStylesFormProps = EntityFormProps<AdaWidgetMagnifierStyles> & {
};