/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";
import React from "react";

import { useTranslation } from "react-i18next";

import styles from "./styles.module.sass";


export default function FormLabel({
  className, text, actions, ...rest
}: FormLabelProps) {
  const { t } = useTranslation();
  const classes = [styles.formLabel, className];
  const labelText = _.isArray(text) ? t(text[0], text[2], text[1]) : text;
  return (
    <div className={classes.join(' ')} {...rest}>
      <span>{labelText}</span>
      {actions}
    </div>
  );
}

export type FormLabelProps = React.ComponentPropsWithRef<"div"> & {
  text?: string | [string, object?, string?],
  actions?: React.ReactNode,
}
