/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Button, { ButtonProps } from "../../buttons/Button";
import styles from "./styles.module.sass";


export default function FormButton({
  className, ...buttonProps
}: FormButtonProps) {
  const classes = [styles.formButton, className];
  return <Button className={classes.join(' ')} {...buttonProps} />;
}


type FormButtonProps = ButtonProps;