/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { BackActivityIndicator, Center } from "common/components";
import "common/i18n";
import { ApiClient } from "common/utils/api";
import "styles.scss";
import createStore from "./app.store";
import reportWebVitals from './reportWebVitals';
import Router from "./router";

const api = new ApiClient();
const store = createStore(api);

ReactDOM.render(
  <Provider store={store} >
    <BrowserRouter>
      <Suspense fallback={<Center><BackActivityIndicator /></Center>}>
        <Router />
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();