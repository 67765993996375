/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import moment from 'moment';

import { useMemo } from "react";

import { BmiSubmission } from "bmi-submissions/bmi-submission.entities";
import { ClipboardButton, Form, NumericField, TextAreaField, TextInputField } from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import styles from "./styles.module.sass";


export default function BmiSubmissionForm<T extends BmiSubmission>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: BmiSubmissionFormProps<T>) {
  const classes = useMemo(() => [styles.bmisubmissionForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  return (
    <Form className={classes} {...rest}>
      <TextInputField
        name={fieldName("createdAt")}
        label={["Submitted At"]}
        value={moment.utc(value?.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}
        error={errors?.createdAt}
        readOnly={readOnly}
        hidden={isHidden("createdAt")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("gender")}
        label={["Gender"]}
        value={_.startCase(value?.gender)}
        error={errors?.gender}
        readOnly={readOnly}
        hidden={isHidden("gender")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("firstName")}
        label={["First Name"]}
        value={value?.firstName}
        error={errors?.firstName}
        readOnly={readOnly}
        hidden={isHidden("firstName")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("lastName")}
        label={["Last Name"]}
        value={value?.lastName}
        error={errors?.lastName}
        readOnly={readOnly}
        hidden={isHidden("lastName")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("email")}
        label={["Email"]}
        value={value?.email}
        error={errors?.email}
        readOnly={readOnly}
        hidden={isHidden("email")}
        onChange={onChange}
        rightElement={
          <ClipboardButton
            style={{ marginTop: 5 }}
            textToCopy={value?.email} />
        }
      />
      <TextInputField
        name={fieldName("phone")}
        label={["Phone"]}
        value={value?.phone}
        error={errors?.phone}
        readOnly={readOnly}
        hidden={isHidden("phone")}
        onChange={onChange}
        rightElement={
          <ClipboardButton
            style={{ marginTop: 5 }}
            textToCopy={value?.phone} />
        }
      />
      <NumericField
        name={fieldName("bmi")}
        label={["BMI Value"]}
        value={value?.bmi}
        error={errors?.bmi}
        readOnly={readOnly}
        hidden={isHidden("bmi")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("concerns")}
        label={["Concerns"]}
        value={value?.concerns?.map(_.startCase)?.join(', ')}
        error={errors?.concerns}
        readOnly={readOnly}
        hidden={isHidden("concerns")}
        onChange={onChange}
      />
      <TextAreaField
        style={{ height: 104 }}
        name={fieldName("message")}
        label={["Message"]}
        value={value?.message}
        error={errors?.message}
        readOnly={readOnly}
        hidden={isHidden("message")}
        onChange={onChange}
      />
      {children}
    </Form>
  );
}

export type BmiSubmissionFormProps<T extends BmiSubmission> = EntityFormProps<T> & {
};