/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { BaseWidget } from "widget/widget.entities";

export interface AdaWidgetTheme {
  color?: string;
  textColor?: string;
  bgColor?: string;
  keyboardBgColor?: string;
  keyboardKeyColor?: string;
  keyboardKeyBgColor?: string;
}

export const adaWidgetButtonPositions = [
  'top-left',
  'top-right',
  'left-center',
  'right-center',
  'bottom-left',
  'bottom-center',
  'bottom-right',
] as const;

export interface AdaWidgetButtonStyles {
  position?: typeof adaWidgetButtonPositions[number];
  margin?: number;
  padding?: number;
  radius?: number;
  size?: number;
  apperanceDelay?: number;
  color?: string;
  hoverColor?: string;
  bgColor?: string;
  bgHoverColor?: string;
}

export interface AdaWidgetMagnifierStyles {
  color?: string;
  bgColor?: string;
  fontSize?: number;
}

export const adaWidgetTemplates = [
  'popup',
  'off-canvas-left',
  'aside-left',
  'off-canvas-right',
  'aside-right',
] as const;

export interface AdaWidgetStyles {
  lightTheme?: AdaWidgetTheme;
  darkTheme?: AdaWidgetTheme;
  template?: typeof adaWidgetTemplates[number];
  overlayColor?: string;
  borderRadius?: number;
  animationDuration?: number;
  buttonStyles?: AdaWidgetButtonStyles;
  magnifierStyles?: AdaWidgetMagnifierStyles;
}

export interface AdaWidgetProfiles {
  accessibility?: boolean;
  epilepsy?: boolean;
  visuallyImpaired?: boolean;
  cognitiveDisability?: boolean;
  adhdFriendly?: boolean;
  blindUsers?: boolean;
}

export const adaWidgetLanguages = [
  'en',
  'uk',
] as const;

export interface AdaWidget extends BaseWidget {
  type: "ada";
  language?: typeof adaWidgetLanguages[number];
  styles?: AdaWidgetStyles;
  profiles?: AdaWidgetProfiles;
}