/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";
import { IconBaseProps } from "react-icons";
import { BiSolidWidget } from "react-icons/bi";
import { BsUniversalAccess } from 'react-icons/bs';
import { RiBodyScanFill } from "react-icons/ri";

import styles from "./styles.module.scss";

export default function WidgetIcon({
  className, type, ...rest
}: WidgetCardProps) {
  const classes = useMemo(() => [styles.widgetIcon, className].join(" "), [className]);

  if (type === 'ada')
    return <BsUniversalAccess className={classes} {...rest} />
  if (type === 'bmi-calculator')
    return <RiBodyScanFill className={classes} {...rest} />
  return <BiSolidWidget className={classes} {...rest} />
}

export type WidgetCardProps = IconBaseProps & {
  type: string,
};