/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { CgProfile } from 'react-icons/cg';
import { useSearchParams } from 'react-router-dom';

import { useIdentity } from 'auth/auth.hooks';
import { Button, Card, CardContent, Grid } from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { isLoading, useAppState, useFormData, useStateErrors } from "common/utils";
import { UpdateProfile } from 'profile/actions';
import ProfileForm from 'profile/components/ProfileForm';
import { Profile } from 'profile/profile.entities';
import { useCallback } from 'react';
import styles from "./styles.module.scss";


export default function ProfilePage() {
  const [searchParams] = useSearchParams();

  // store
  const identity = useIdentity();
  const [saveState, dispatch] = useAppState((state) => state.profile.save);

  // form data
  const { formData, formErrors, setFormErrors, onChange, changes } =
    useFormData<Profile>(identity?.profile, {} as any, [identity]);
  useStateErrors(saveState, setFormErrors);

  const updateProfile = useCallback(() => {
    dispatch(UpdateProfile(identity!.profile!.id!, changes))
  }, [identity, changes])

  return (
    <>
      <PageHeader
        icon={<CgProfile size={21} style={{ marginTop: -1 }} />}
        title={identity?.profile?.firstName
          ? `${identity.profile.firstName} ${identity.profile.lastName}`
          : "Create Profile"}
        backButtonPath={searchParams.get("backButtonPath")}>
        <Button
          text="Save"
          intent="primary"
          onClick={updateProfile}
          hidden={_.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
      </PageHeader>
      <PageContent className={styles.wbProfilePage}>
        <Grid md={2} xs={1} gap={20}>
          <Card elevation={1}>
            <CardContent>
              <ProfileForm
                value={formData}
                errors={formErrors}
                onChange={onChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </PageContent>
    </>
  );
}
