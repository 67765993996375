/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ColorPickerField, DropdownField, FormField, SliderField } from "common/components";
import Form, { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { AdaWidgetStyles, adaWidgetTemplates } from "widget/ada/ada-widget.entities";
import AdaWidgetButtonStylesForm from "widget/ada/components/AdaWidgetButtonStylesForm";
import AdaWidgetMagnifierStylesForm from "widget/ada/components/AdaWidgetMagnifierStylesForm";
import AdaWidgetThemeForm from "widget/ada/components/AdaWidgetThemeForm";
import styles from "./styles.module.sass";


export default function AdaWidgetStylesForm({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: AdaWidgetStylesFormProps) {
  const { t } = useTranslation();
  const classes = useMemo(() => [styles.adaWidgetStylesForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  const templateOptions = useMemo(() => adaWidgetTemplates.map(i => ({
    id: i,
    text: t(`AdaWidgetTemplates.${i}`),
    value: i,
  })), []);

  return (
    <Form className={classes} {...rest}>
      <DropdownField
        nullable
        name={fieldName("template")}
        label={["Template"]}
        options={templateOptions}
        value={value?.template}
        error={errors?.template}
        readOnly={readOnly}
        hidden={isHidden("template")}
        onChange={onChange}
      />
      <FormField
        borderless
        collapsed
        label="Light Theme"
        error={errors?.lightTheme} >
        <AdaWidgetThemeForm
          name={fieldName("lightTheme")}
          value={value?.lightTheme}
          errors={errors?.lightTheme}
          readOnly={readOnly}
          hidden={isHidden("lightTheme")}
          onChange={onChange}
        />
      </FormField>
      <FormField
        borderless
        collapsed
        label="Dark Theme"
        error={errors?.darkTheme} >
        <AdaWidgetThemeForm
          name={fieldName("darkTheme")}
          value={value?.darkTheme}
          errors={errors?.darkTheme}
          readOnly={readOnly}
          hidden={isHidden("darkTheme")}
          onChange={onChange}
        />
      </FormField>
      <ColorPickerField
        name={fieldName("overlayColor")}
        label="Overlay Color"
        value={value?.overlayColor}
        error={errors?.overlayColor}
        readOnly={readOnly}
        hidden={isHidden("overlayColor")}
        onChange={onChange}
      />
      <FormField
        borderless
        collapsed
        label="Button Style"
        error={errors?.buttonStyles}>
        <AdaWidgetButtonStylesForm
          name={fieldName("buttonStyles")}
          value={value?.buttonStyles}
          errors={errors?.buttonStyles}
          readOnly={readOnly}
          onChange={onChange}
        />
      </FormField>
      <FormField
        borderless
        collapsed
        label="Magnifier Style"
        error={errors?.magnifierStyles}>
        <AdaWidgetMagnifierStylesForm
          name={fieldName("magnifierStyles")}
          value={value?.magnifierStyles}
          errors={errors?.magnifierStyles}
          readOnly={readOnly}
          onChange={onChange}
        />
      </FormField>
      <SliderField
        name={fieldName("animationDuration")}
        label="Animation Duration (ms)"
        value={value?.animationDuration}
        error={errors?.animationDuration}
        readOnly={readOnly}
        hidden={isHidden("animationDuration")}
        onChange={onChange}
        min={0}
        max={1500}
        stepSize={50}
        labelStepSize={500}
      />
      {children}
    </Form>
  );
}

export type AdaWidgetStylesFormProps = EntityFormProps<AdaWidgetStyles> & {
};