/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { BiSolidWidget } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import {
  BackAsyncError, Backout, Button, Grid, GridContentLoader
} from "common/components";
import { AsyncStateBuilder } from "common/utils";
import { BaseWidget } from '../../../widget.entities';
import { useGetAllWidgets } from "../../../widget.hooks";
import WidgetCard from '../../components/WidgetCard';
import styles from "./styles.module.scss";


export default function WidgetsGrid({ filterQuery }: WidgetsGridProps) {
  const navigate = useNavigate();
  const { getAllWidgets, getAllWidgetsState, widgets } = useGetAllWidgets({
    filter: (widget) =>
      _.isEmpty(filterQuery) || _.values(widget).join(" ").toLowerCase().includes(filterQuery!),
  });

  return (
    <AsyncStateBuilder<BaseWidget[]>
      state={getAllWidgetsState}
      onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
      onFailed={(error) => <BackAsyncError error={error} onTryAgain={getAllWidgets} />}
      onValueEmpty={() => (
        <div style={{ marginTop: 150 }}>
          <Backout
            icon={
              <BiSolidWidget style={{ marginTop: -3, marginLeft: -2 }} />
            }
            title="No Widgets Yet">
            <div style={{ marginTop: 20 }}>
              <Button
                outlined
                text={["Add"]}
                onClick={() => navigate("/widgets/ada/new")} />
            </div>
          </Backout>
        </div>
      )}
      onValueNotEmpty={() =>
        <Grid md={3} sm={2} xs={1} gap={20} >
          {widgets.map(widget =>
            <WidgetCard
              interactive
              key={widget.id}
              className={styles.card}
              onClick={() => navigate(`${widget.type}/${widget.id}`)}
              value={widget} />
          )}
        </Grid>
      }
    />
  );
}

export type WidgetsGridProps = {
  filterQuery?: string | null;
}
