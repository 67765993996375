/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useMemo } from "react";

import { SwitchField } from "common/components";
import Form, { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { AdaWidgetProfiles } from "widget/ada/ada-widget.entities";
import styles from "./styles.module.sass";


export default function AdaWidgetProfilesForm({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: AdaWidgetProfilesFormProps) {
  const classes = useMemo(() => [styles.adaWidgetProfilesForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  return (
    <Form
      className={classes}
      {...rest}>
      <SwitchField
        name={fieldName("accessibility")}
        label="Accessibility"
        value={_.get(value, 'accessibility', true)}
        error={errors?.accessibility}
        readOnly={readOnly}
        hidden={isHidden("accessibility")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("epilepsy")}
        label="Epilepsy"
        value={_.get(value, 'epilepsy', true)}
        error={errors?.epilepsy}
        readOnly={readOnly}
        hidden={isHidden("epilepsy")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("visuallyImpaired")}
        label="Visually Impaired"
        value={_.get(value, 'visuallyImpaired', true)}
        error={errors?.visuallyImpaired}
        readOnly={readOnly}
        hidden={isHidden("visuallyImpaired")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("cognitiveDisability")}
        label="Cognitive Disability"
        value={_.get(value, 'cognitiveDisability', true)}
        error={errors?.cognitiveDisability}
        readOnly={readOnly}
        hidden={isHidden("cognitiveDisability")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("adhdFriendly")}
        label="ADHD Friendly"
        value={_.get(value, 'adhdFriendly', true)}
        error={errors?.adhdFriendly}
        readOnly={readOnly}
        hidden={isHidden("adhdFriendly")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("blindUsers")}
        label="Blind Users"
        value={_.get(value, 'blindUsers', true)}
        error={errors?.blindUsers}
        readOnly={readOnly}
        hidden={isHidden("blindUsers")}
        onChange={onChange}
      />
      {children}
    </Form>
  );
}

export type AdaWidgetProfilesFormProps = EntityFormProps<AdaWidgetProfiles> & {
};