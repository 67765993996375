/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Domain } from "domain/domain.entities";
import { AsyncAction, failed, loading, success } from "common/utils";
import { DeleteDomainState } from "../domain.store";


export function DeleteDomain(id: string): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(DeleteDomainState(loading()));
    try {
      const { value } = await api.delete(`/domains/${id}`);
      dispatch(DeleteDomainState(success(value)));
    } catch (error) {
      dispatch(DeleteDomainState(failed(error)));
    }
  };
}
