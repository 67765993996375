/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import ContentLoader from 'react-content-loader';
import { useEffect, useMemo, useState } from 'react';

import Grid, { GridProps } from "common/components/flex/Grid";
import styles from "./styles.module.scss";


export default function ({
  className, forms = 2, apperanceDelay = 500, gap = 20, ...gridProps
}: PageContentLoaderProps) {
  const [opacity, setOpacity] = useState(apperanceDelay ? 0 : 1);
  useEffect(() => {
    if (apperanceDelay && opacity < 1) {
      setTimeout(() => {
        setOpacity(opacity + 0.1);
      }, apperanceDelay / 10);
    }
  }, [apperanceDelay, opacity]);
  const classes = useMemo(() => {
    const cls = [styles.pageContentLoader];
    if (className) cls.push(className);
    return cls.join(' ').trim();
  }, [className]);
  return (
    <div className={classes}>
      <ContentLoader style={{ opacity }} viewBox="0 0 100 3">
        <rect x="0" y="0" rx="1" ry="3" width="50" height="3" />
      </ContentLoader>
      <Grid gap={gap} {...gridProps}>
        {_.range(0, forms).map(() => (
          <ContentLoader style={{ opacity }} viewBox="0 0 100 110">
            <rect x="0" y="0" rx="1" ry="2" width="40" height="5" />
            <rect x="0" y="8" rx="2" ry="2" width="100" height="20" />
            <rect x="0" y="30" rx="2" ry="2" width="100" height="80" />
          </ContentLoader>
        ))}
      </Grid>
    </div>
  )
}

export type PageContentLoaderProps = GridProps & {
  forms?: number,
  apperanceDelay?: number,
}