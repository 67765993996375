/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Dialog } from "@blueprintjs/core";
import { useState } from "react";
import { BiSolidWidget } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import { Button } from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import WidgetIcon from "widget/common/components/WidgetIcon";
import WidgetsGrid from "widget/common/containers/WidgetsGrid";
import { useGetAllWidgets } from "widget/widget.hooks";
import styles from './styles.module.scss';
import { useIdentity } from "auth/auth.hooks";

export default function WidgetsPage() {
  const navigate = useNavigate();
  const { getAllWidgets } = useGetAllWidgets();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [isAddDialogVisible, showAddDialog] = useState(false);

  return (
    <>
      <PageHeader
        icon={<BiSolidWidget size={23} style={{ marginRight: 1, marginTop: -1 }} />}
        title={['Widgets']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text="Add"
          intent="success"
          onClick={() => showAddDialog(true)}
        />
        <Button
          minimal
          icon="refresh"
          onClick={getAllWidgets}
        />
      </PageHeader>
      <PageContent>
        <WidgetsGrid filterQuery={searchQuery} />
      </PageContent>
      <Dialog
        style={{width: 'auto'}}
        title={"Widget Catalog"}
        isCloseButtonShown={true}
        isOpen={isAddDialogVisible}
        onClose={() => showAddDialog(false)} >
        <div className={styles.widgetsToAdd}>
          {[{
            type: "ada",
            name: "ADA",
            visible: true,
          }, {
            type: "bmi-calculator",
            name: 'BMI Calculator',
            visible: true,
          }, {
            type: "roi-calculator",
            name: 'ROI Calculator',
            visible: true,
          }, {
            type: "audit-report",
            name: 'Audit Report',
            visible: true,
          }].map(widget => widget.visible == false ? null :
            <div
              key={widget.type}
              className={styles.widgetToAdd}
              onClick={() => navigate(`/widgets/${widget.type}/new`)} >
              <WidgetIcon type={widget.type} />
              <span>{widget.name}</span>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}
