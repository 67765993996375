/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useNavigate } from "react-router-dom";

import { ApiError } from "common/utils";
import { Button } from '../../buttons';
import Backout, { BackoutProps } from '../Backout';


export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Backout
      tall
      icon="search"
      title="404"
      message="Page Not Found"
      action={
        <Button
          outlined
          text="Home"
          onClick={() => navigate("/")}
        />
      }
    />
  );
}

export type PageNotFoundProps = { error: ApiError } & BackoutProps;