/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createSlice } from "@reduxjs/toolkit";

import { AsyncState } from "common/utils/async-state";
import { BaseWidget, WidgetViewStatsBaseReport } from "widget/widget.entities";


export const WidgetStore = createSlice({
  name: "widget",
  initialState: {
    getAll: {} as AsyncState<BaseWidget[]>,
    save: {} as AsyncState<BaseWidget>,
    delete: {} as AsyncState<BaseWidget>,
    viewStats: {
      getBaseReport: {} as AsyncState<WidgetViewStatsBaseReport>
    }
  },
  reducers: {
    GetAllWidgetsState: (
      state,
      action: { payload: AsyncState<BaseWidget[]> },
    ) => {
      state.getAll = action.payload;
    },
    SaveWidgetState: (
      state,
      action: { payload: AsyncState<BaseWidget> },
    ) => {
      state.save = action.payload;
      if (action.payload.value && state.getAll.value) {
        const newValue = Array.from(state.getAll.value!);
        const idx = newValue.findIndex((i) => i.id === action.payload.value!.id);
        if (idx === -1) {
          newValue.push(action.payload.value!);
        } else {
          newValue[idx] = action.payload.value!;
        }
        state.getAll = { ...state.getAll, value: newValue }
      }
    },
    DeleteWidgetState: (
      state,
      action: { payload: AsyncState<BaseWidget> },
    ) => {
      state.delete = action.payload;
      if (action.payload.value && state.getAll.value) {
        const newValue = Array.from(state.getAll.value!);
        const idx = newValue.findIndex((i) => i.id === action.payload.value!.id);
        if (idx !== -1) {
          newValue.splice(idx, 1);
          state.getAll = { ...state.getAll, value: newValue }
        }
      }
    },
    GetWidgetViewStatsBaseReportState: (
      state,
      action: { payload: AsyncState<WidgetViewStatsBaseReport> },
    ) => {
      state.viewStats.getBaseReport = action.payload;
    },
  },
});

export const {
  GetAllWidgetsState,
  SaveWidgetState,
  DeleteWidgetState,
  GetWidgetViewStatsBaseReportState,
} = WidgetStore.actions;

export const { reducer: widgetReducer } = WidgetStore;

export type WidgetState = ReturnType<typeof WidgetStore.getInitialState>
