/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { AsyncAction, failed, loading, success } from "common/utils";
import { Domain } from "domain/domain.entities";
import { SaveDomainState } from "../domain.store";


export function UpdateDomain(domain: Domain, changes: Partial<Domain>): AsyncAction {
  return async (dispatch, _getStore, api) => {
    dispatch(SaveDomainState(loading()));
    try {
      const { value } = await api.put(`/domains/${domain.id}`, {
        data: changes,
      })
      dispatch(SaveDomainState(success(value)));
    } catch (error) {
      dispatch(SaveDomainState(failed(error)));
    }
  };
}
