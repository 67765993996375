/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { GetAllWidgetsState } from "widget/widget.store";


export function GetAllWidgets(): AsyncAction {
  return async (dispatch, _getState, api) => {
    dispatch(GetAllWidgetsState(loading()));
    try {
      const { value } = await api.get('/widgets');
      dispatch(GetAllWidgetsState(success(value)));
    } catch (error) {
      dispatch(GetAllWidgetsState(failed(error)));
    }
  };
}