/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Dialog, DialogProps } from '@blueprintjs/core';
import { useMemo } from 'react';

import { ClipboardButton } from 'common/components';
import { getApiUrl } from 'common/utils';
import { BaseWidget } from 'widget/widget.entities';
import styles from './styles.module.scss';

export default function ({
  className, widget, width, height, ...rest
}: IFramePublishDialogProps) {
  const classes = useMemo(() => {
    const cls = [styles.publishDialog, className];
    return cls.join(" ").trim();
  }, [className])

  return (
    <Dialog
      className={classes}
      title={"Code Snippet"}
      usePortal={false}
      isCloseButtonShown={true}
      {...rest}>
      <div className='box'>
        <code>
          {`<iframe width="${width}" height="${height}" frameBorder="0"`}<br />
          &nbsp;{`src="${getApiUrl()}/widgets/${widget.type}/${widget.id}-v${widget.version}.html">`}<br />
          {'</iframe>'}
        </code>
        <ClipboardButton
          intent='warning'
          textToCopy={
            `<iframe width="${width}" height="${height}" frameBorder="0"
               src="${getApiUrl()}/widgets/${widget.type}/${widget.id}-v${widget.version}.html"></iframe>`
          } />
      </div>
      <p>Copy and paste this code into <code>{`<body>`}</code> section of your website</p>
    </Dialog>
  );
}

export type IFramePublishDialogProps = DialogProps & {
  className?: string;
  widget: BaseWidget;
  width: string;
  height: string;
}