/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createSlice } from "@reduxjs/toolkit";

import { AsyncState } from "common/utils/async-state";
import { Domain } from "domain/domain.entities";


export const DomainStore = createSlice({
  name: "domain",
  initialState: {
    getAll: {} as AsyncState<Domain[]>,
    save: {} as AsyncState<{ status: string, entity: Domain }>,
    delete: {} as AsyncState<Domain>,
  },
  reducers: {
    GetAllDomainsState: (
      state,
      action: { payload: AsyncState<Domain[]> },
    ) => {
      state.getAll = action.payload;
    },
    SaveDomainState: (
      state,
      action: { payload: AsyncState<{ status: string, entity: Domain }> },
    ) => {
      state.save = action.payload;
      if (action.payload.value && state.getAll.value) {
        const newValue = Array.from(state.getAll.value!);
        const idx = newValue.findIndex((i) => i.id === action.payload.value!.entity.id);
        if (idx === -1) {
          newValue.push(action.payload.value!.entity);
        } else {
          newValue[idx] = action.payload.value!.entity;
        }
        state.getAll = { ...state.getAll, value: newValue }
      }
    },
    DeleteDomainState: (
      state,
      action: { payload: AsyncState<Domain> },
    ) => {
      state.delete = action.payload;
      if (action.payload.value && state.getAll.value) {
        const newValue = Array.from(state.getAll.value!);
        const idx = newValue.findIndex((i) => i.id === action.payload.value!.id);
        if (idx !== -1) {
          newValue.splice(idx, 1);
          state.getAll = { ...state.getAll, value: newValue }
        }
      }
    },
  },
});

export const {
  GetAllDomainsState,
  SaveDomainState,
  DeleteDomainState,
} = DomainStore.actions;

export const { reducer: domainReducer } = DomainStore;

export type DomainState = ReturnType<typeof DomainStore.getInitialState>
