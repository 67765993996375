/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { GetAllBmiSubmissionsState } from '../bmi-submission.store';


export function GetAllBmiSubmissions(): AsyncAction {
  return async (dispatch, _getState, api) => {
    dispatch(GetAllBmiSubmissionsState(loading()));
    try {
      const { value } = await api.get('/bmi-submissions');
      dispatch(GetAllBmiSubmissionsState(success(value)));
    } catch (error) {
      dispatch(GetAllBmiSubmissionsState(failed(error)));
    }
  };
}