/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { useTranslation } from "react-i18next";

import { ApiError } from "common/utils";
import { Button } from '../../buttons';
import Backout, { BackoutProps } from '../Backout';


export default function BackAsyncError({
  error, onTryAgain, ...rest
}: BackAsyncErrorProps) {
  const { t } = useTranslation();
  const params = _.omit(error, "code", "message");
  const titleText = t(`ApiErrors.${error.code}`, error.code, params);
  const msgText = t(`ApiErrorInstractions.${error.code}`,
    process.env.NODE_ENV === 'development' ? error.message : '', params);
  const icon = error.code === "http-error" || error.code === "ws-error"
    ? "offline" : "warning-sign";
  return (
    <Backout
      tall
      icon={icon}
      title={titleText}
      message={msgText}
      action={onTryAgain &&
        <Button
          outlined
          text={["TryAgain"]}
          onClick={onTryAgain} />}
      {...rest}
    />
  );
}

export type BackAsyncErrorProps = BackoutProps & {
  error: ApiError,
  onTryAgain?: () => void,
}