/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { AdaWidget } from "widget/ada/ada-widget.entities";
import { SaveWidgetState } from "widget/widget.store";


export function CreateAdaWidget(widget: AdaWidget): AsyncAction {
  return async (dispatch, _getStore, api) => {
    dispatch(SaveWidgetState(loading()));
    try {
      const { value } = await api.post('/widgets/ada', {
        data: widget,
      })
      dispatch(SaveWidgetState(success(value)));
    } catch (error) {
      dispatch(SaveWidgetState(failed(error)));
    }
  };
}
