/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useMemo } from 'react';
import { Icon } from '@blueprintjs/core';

import { Column } from "common/components";
import Card, { CardProps } from "common/components/cards/Card";
import { Domain } from "domain/domain.entities";
import styles from "./styles.module.scss";


export default function DomainCard({
  className, value, onClick, children, ...rest
}: DomainCardProps) {
  const classes = useMemo(() => [styles.domainCard, className].join(" "), [className]);

  return (
    <Card
      interactive={onClick != null}
      className={classes}
      onClick={onClick}
      {...rest}>
      <div className={styles.domainIcon}>
        <Icon icon="globe" color={value.active ? '#f9bf3bbb' : '#bbb'} />
      </div>
      <Column fill>
        <h4>{value.name}</h4>
        {children}
      </Column>
    </Card>
  );
}

export type DomainCardProps = CardProps & {
  value: Domain,
};