/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { Domain } from "domain/domain.entities";
import { SaveDomainState } from "../domain.store";


export function CreateDomain(domain: Domain): AsyncAction {
  return async (dispatch, _, api) => {
    dispatch(SaveDomainState(loading()));
    try {
      const { value } = await api.post('/domains', {
        data: domain,
      })
      dispatch(SaveDomainState(success(value)));
    } catch (error) {
      dispatch(SaveDomainState(failed(error)));
    }
  };
}
