/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import _ from "lodash";

export interface ApiError {
  code: string,
  message?: string,
  [key: string]: any,
}
export interface AsyncState<V, E = ApiError> {
  value?: V | null,
  error?: E | null,
  isLoading?: boolean,
}

export function failed<V>(error: any, value?: V): AsyncState<V> {
  if (error.isAxiosError && error.message === 'Network Error') {
    return {
      error: {
        code: 'http-error',
      },
      isLoading: false,
      value,
    };
  }
  if (error.type === 'TransportError') {
    return {
      error: {
        code: 'ws-error',
      },
      isLoading: false,
      value,
    };
  }
  if (error.code == null || !_.isString(error.code))
    return {
      error: {
        code: 'server-error',
      },
      isLoading: false,
      value,
    }
  return { error, value, isLoading: false };
}

export function loading<V>(value?: V): AsyncState<V> {
  return { value, isLoading: true };
}

export function success<V>(value: V): AsyncState<V> {
  return { value, isLoading: false, error: null };
}

export function empty<V>(): AsyncState<V> {
  return { value: undefined, error: undefined, isLoading: false };
}

export const isLoading = (state?: AsyncState<any>) => state?.isLoading === true;
export const isNotLoading = (state?: AsyncState<any>) => !isLoading(state);
export const isSuccessful = (state?: AsyncState<any>) => isNotLoading(state) && !state?.error && state?.value !== undefined;
export const isNotSuccessful = (state?: AsyncState<any>) => !isSuccessful(state);
export const isFailed = (state?: AsyncState<any>) => !isLoading(state) && state?.error != null && state.value == null;
export const isEmpty = (state?: AsyncState<any>) => _.isEmpty(state);
export const isUseless = (state?: AsyncState<any>) => isEmpty(state) || isFailed(state);
export const isComplete = (state?: AsyncState<any>) => isSuccessful(state) || isFailed(state);
export const isNotComplete = (state?: AsyncState<any>) => !isComplete(state);

export function AsyncStateSelector<V>(state: AsyncState<V>, {
  onValueEmpty, onValueNotEmpty, onValue, onSuccess, onLoading, onError, onFailed, orElse,
}: AsyncStateSelectorProps<V>): JSX.Element | null {
  if (state) {
    if (onValueEmpty != null && _.isEmpty(state.value)) {
      return onValueEmpty();
    }
    if (onValueNotEmpty != null && _.isEmpty(state.value) === false) {
      return onValueNotEmpty(state.value!);
    }
    if (onValue != null && state.value != null) {
      return onValue(state.value);
    }
    if (onSuccess != null && isSuccessful(state)) {
      return onSuccess(state.value);
    }
    if (onLoading != null && isLoading(state)) {
      return onLoading();
    }
    if (onError != null && state.error) {
      return onError(state.error!);
    }
    if (onFailed != null && isFailed(state)) {
      return onFailed(state.error!);
    }
  }
  if (orElse != null) return orElse();
  return null;
}

export type AsyncStateSelectorProps<V> = {
  onValueEmpty?: () => JSX.Element | null,
  onValueNotEmpty?: (value: V) => JSX.Element | null,
  onValue?: (value: V) => JSX.Element | null,
  onSuccess?: (value?: V | null) => JSX.Element | null,
  onLoading?: (value?: V | null) => JSX.Element | null,
  onFailed?: (error: ApiError, value?: V) => JSX.Element | null,
  onError?: (error: ApiError, value?: V) => JSX.Element | null,
  orElse?: () => JSX.Element | null,
};

export function AsyncStateBuilder<V>({
  state, onValueEmpty, onValueNotEmpty, onValue, onSuccess, onLoading, onFailed, orElse,
}: AsyncStateBuilderProps<V>) {
  if (state) {
    if (onLoading != null && isLoading(state)) {
      return onLoading();
    }
    if (onFailed != null && isFailed(state)) {
      return onFailed(state.error!);
    }
    if (onValueEmpty != null && _.isEmpty(state.value)) {
      return onValueEmpty();
    }
    if (onValueNotEmpty != null && _.isEmpty(state.value) === false) {
      return onValueNotEmpty(state.value!);
    }
    if (onValue != null && state.value != null) {
      return onValue(state.value);
    }
    if (onSuccess != null && isSuccessful(state)) {
      return onSuccess(state.value);
    }
  }
  if (orElse != null) return orElse();
  return null;
};

export type AsyncStateBuilderProps<V> = AsyncStateSelectorProps<V> & {
  state: AsyncState<V>,
}