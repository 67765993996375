/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BackActivityIndicator, BackAsyncError, Center, PageContent, PageHeader } from 'common/components';
import { AsyncStateBuilder, useAppState } from 'common/utils';
import { GetCurrentSubscription } from 'subscription/actions/get-current-subscription.action';
import ManageSubscription from 'subscription/components/ManageSubscription';
import SubscriptionPlans from 'subscription/components/SubscriptionPlans';


export default function SubscriptionPage() {
  const { state } = useLocation();

  const [getCurrentSubscriptionState, dispatch] =
    useAppState((store) => store.subscription.getCurrentSubscription);

  const getCurrentSubscription = useCallback(() => {
    dispatch(GetCurrentSubscription())
  }, []);
  useEffect(() => {
    getCurrentSubscription();
  }, []);

  return (
    <>
      <PageHeader
        title={"Subscription"}
        backButtonPath={state?.backpath}>
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder state={getCurrentSubscriptionState}
          onLoading={() => <BackActivityIndicator tall />}
          onFailed={(error) => <BackAsyncError error={error} onTryAgain={getCurrentSubscription} />}
          onValue={() =>
            <Center>
              <ManageSubscription style={{ margin: 20 }} />
            </Center>
          }
          onValueEmpty={() => <SubscriptionPlans />}
        />
      </PageContent>
    </>
  );
}
