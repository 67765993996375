/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import ContentLoader from 'react-content-loader';

import Grid, { GridProps } from "common/components/flex/Grid";
import { useEffect, useState } from 'react';


export default function ({ items = 9, apperanceDelay = 500, ...gridProps }: GridCardsLoaderProps) {
  const [opacity, setOpacity] = useState(apperanceDelay ? 0 : 1);
  useEffect(() => {
    if (apperanceDelay && opacity < 1) {
      setTimeout(() => {
        setOpacity(opacity + 0.1);
      }, apperanceDelay / 10);
    }
  }, [apperanceDelay, opacity]);
  return (
    <Grid {...gridProps}>
      {_.range(0, items).map((i) => (
        <ContentLoader key={`loaderItem${i}`} style={{ opacity }} width="100%" height={120} viewBox="0 0 100 40">
          <rect x="0" y="0" rx="2" ry="2" width="100" height="9" />
          <rect x="0" y="12" rx="2" ry="2" width="100" height="28" />
        </ContentLoader>
      ))}
    </Grid>
  )
}

export type GridCardsLoaderProps = GridProps & {
  items?: number,
  apperanceDelay?: number,
}