/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Dialog, DialogProps } from '@blueprintjs/core';
import { useMemo } from 'react';

import SubscriptionPlans from 'subscription/components/SubscriptionPlans';
import styles from './styles.module.scss';

export default function ({
  className, ...rest
}: SubscriptionPlansDialogProps) {
  const classes = useMemo(() => {
    const cls = [styles.subscriptionPlansDialog, className];
    return cls.join(" ").trim();
  }, [className])

  return (
    <Dialog
      className={classes}
      title={"Subscription Plans"}
      usePortal={false}
      isCloseButtonShown={true}
      {...rest}>
      <SubscriptionPlans />
    </Dialog>
  );
}

export type SubscriptionPlansDialogProps = DialogProps & {
}