/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useMemo } from "react";
import { useTranslation } from 'react-i18next';

import { Button, DropdownField, Form, SwitchField, TextInputField } from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { useGetAllDomains } from "domain/domain.hooks";
import { BaseWidget } from "widget/widget.entities";
import styles from "./styles.module.sass";


export default function WidgetForm<T extends BaseWidget>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: BaseWidgetFormProps<T>) {
  const { t } = useTranslation();
  const classes = useMemo(() => [styles.widgetForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  const { domains, getAllDomains, getAllDomainsState } = useGetAllDomains();

  const domainOptions = useMemo(() => {
    return domains.map(domain => ({
      id: domain.id!,
      value: domain.id!,
      text: domain.name + (domain.active ? '' : ' (inactive)'),
    }))
  }, [domains])

  const domainError = useMemo(() => {
    if (getAllDomainsState.error) {
      const error = getAllDomainsState.error!;
      const params = _.omit(error, "code", "message");
      return t(`ApiErrors.${error.code}`, error.code, params);
    }
  }, [getAllDomainsState.error])

  return (
    <Form className={classes} {...rest}>
      <SwitchField
        name={fieldName("active")}
        label={["Active"]}
        value={value?.active}
        error={errors?.active}
        readOnly={readOnly}
        hidden={isHidden("active")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("name")}
        label={["Name"]}
        value={value?.name}
        error={errors?.name}
        readOnly={readOnly}
        hidden={isHidden("name")}
        onChange={onChange}
      />
      <DropdownField
        nullable={_.isEmpty(value?.domain)}
        name={fieldName("domain")}
        label={["Domain"]}
        actions={
          <>
            <Button
              key="connect-action"
              minimal
              text={["Connect"]}
              onClick={() => window.open("/domains/new", "_blan")} />
            <Button
              minimal
              style={{ marginBottom: -1 }}
              icon="refresh"
              onClick={getAllDomains} />
          </>
        }
        options={domainOptions}
        value={value?.domain}
        error={errors?.domain || domainError}
        readOnly={readOnly}
        hidden={isHidden("domain")}
        onChange={onChange}
      />
      {children}
    </Form>
  );
}

export type BaseWidgetFormProps<T extends BaseWidget> = EntityFormProps<T> & {
};