/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useContext } from "react";

import { IdentityContext } from "auth/guards/Auth";


export function useIdentity() {
  const identity = useContext(IdentityContext);
  return identity;
}