/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Outlet } from 'react-router-dom';

import { Card, CardHeader, FormButton } from "common/components";
import { FormProps } from 'common/components/form/Form';
import { useIdentity } from 'auth/auth.hooks';
import { CreateProfile, UpdateProfile } from 'profile/actions';
import ProfileForm from 'profile/components/ProfileForm';
import { Profile } from 'profile/profile.entities';
import { useAppState, useFormData, useStateErrors } from "common/utils";
import styles from "./styles.module.sass";


export default function ProfileGuard({ layout, ...formProps }: ProfileGuardProps) {
  const identity = useIdentity();
  const [saveState, dispatch] = useAppState((store) => store.profile.save);

  const { formData, formErrors, setFormErrors, onChange, changes } =
    useFormData<Profile>({
      firstName: identity?.user.firstName || '',
      lastName: identity?.user.lastName || '',
      email: identity?.user.email || '',
      category: 'freelancer',
      ...identity?.profile,
    }, null, []);
  useStateErrors(saveState, setFormErrors);

  const child = identity?.profile?.category
    ? <Outlet />
    : (
      <div className='fullscreenGuard' >
        <Card className={styles.card} elevation={1} >
          <CardHeader title={["ProfileGuard.Title"]} />
          <div className={styles.container}>
            <ProfileForm
              className={styles.form}
              value={formData}
              errors={formErrors}
              onChange={onChange}
              {...formProps}>
              <FormButton
                intent='primary'
                text={['Save']}
                loading={saveState.isLoading}
                onClick={() => formData.id
                  ? dispatch(UpdateProfile(formData.id, changes))
                  : dispatch(CreateProfile(formData))}
              />
            </ProfileForm>
          </div>
        </Card>
      </div>
    );
  return layout == null ? child : layout(child);
}

export type ProfileGuardProps = Omit<FormProps, "onChange"> & {
  layout?: (child: JSX.Element | null) => JSX.Element,
}