/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Position } from '@blueprintjs/core';
import { Popover2, Popover2Props } from '@blueprintjs/popover2';
import { OnChangeEventHandler } from "common/utils";
import { useMemo, useState } from 'react';
import { ChromePicker, ChromePickerProps } from 'react-color';
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components/buttons';
import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.scss";


export default function ColorPickerField({
  className, label, hidden, disabled, error, readOnly,
  name, value, onChange, popoverProps, ...rest
}: ColorPickerFieldProps) {
  const { t } = useTranslation();

  const [isPickerShown, showPicker] = useState(false);

  const labelText = _.isArray(label) ? t(label[0], label[2], label[1]) : label;
  const classes = useMemo(() => {
    const classes = [styles.colorPickerField, className];
    if (disabled)
      classes.push(styles.disabled)
    if (readOnly)
      classes.push(styles.readOnly)
    return classes.join(' ').trim();
  }, [className]);

  return (
    <FormField
      borderless
      className={classes}
      disabled={disabled}
      label={labelText}
      hidden={hidden}
      error={error} >
      <Popover2
        fill
        // minimal
        position={Position.BOTTOM_LEFT}
        isOpen={isPickerShown}
        autoFocus={false}
        onInteraction={(show) => !show && showPicker(false)}
        content={
          <ChromePicker
            color={value}
            onChange={onChange == null && readOnly ? undefined : (color) => {
              let value = color.hex;
              if (color.rgb.a != null && color.rgb.a < 1)
                value += Math.round(255 * color.rgb.a).toString(16).padStart(2, '0');
              onChange!({ target: { name, value } });
            }}
            {...rest}
          />
        }
        {...popoverProps}>
        <Button minimal outlined small style={{ backgroundColor: value }}
          disabled={readOnly || disabled}
          onClick={() => showPicker(true)}
        />
      </Popover2>
    </FormField>
  );
}



export type ColorPickerFieldProps = FormFieldProps & Omit<ChromePickerProps, "onChange"> & {
  name: string,
  onChange?: OnChangeEventHandler<string>
  value?: string,
  readOnly?: boolean,
  popoverProps?: Popover2Props,
}