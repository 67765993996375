/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as _ from 'lodash';
import PropTypes, { InferProps } from "prop-types";

import styles from "./styles.module.sass";


export default function Avatar({
  className, hidden, name, picture, size = 40, ...rest
}: AvatarProps) {
  if (hidden) return null;

  const classes = [styles.container, className].join(" ");
  return (
    <div className={classes} {...rest}
      style={{ width: size!, height: size! }}>
      {name &&
        <span style={{fontSize: size! * 0.45}}>{name.split(' ').map(part => part[0].toUpperCase()).splice(0, 2)}</span>
      }
      {picture &&
        <img alt="" src={picture} width={size! - 1} height={size! - 1}
          onError={(event: any) => event.target.style.display = 'none'} />
      }
    </div>
  );
}

Avatar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  picture: PropTypes.string,
  size: PropTypes.number,
  hidden: PropTypes.bool,
};

export type AvatarProps = InferProps<typeof Avatar.propTypes>