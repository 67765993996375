/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EntityForm } from "common/components";
import { EntityFormProps } from "common/components/form/EntityForm";
import { Profile, profileCategories } from "profile/profile.entities";
import styles from "./styles.module.sass";


export default function ProfileForm<T extends Profile>({
  className, readOnly, value, errors, onChange,
  children, fields, ...rest
}: ProfileFormProps<T>) {
  const classes = useMemo(() => [styles.baseProfileForm, className].join(" "), [className]);
  const { t } = useTranslation();

  return (
    <EntityForm<Profile>
      className={classes}
      value={value}
      onChange={onChange}
      fields={[{
        type: "input",
        path: "firstName",
        label: ["First Name"]
      }, {
        type: "input",
        path: "lastName",
        label: ["Last Name"]
      }, {
        type: "input",
        path: "email",
        label: ["Email"]
      }, {
        type: 'dropdown',
        path: 'category',
        label: ["Organization Type"],
        options: profileCategories.map(id => ({
          id: id,
          text: t(`ProfileCategories.${id}`),
          value: id,
        }))
      }]}
      {...rest}>
      {children}
    </EntityForm>
  );
}

export type ProfileFormProps<T extends Profile> = EntityFormProps<T> & {
};