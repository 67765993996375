/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { DeleteWidgetState } from "../../widget.store";


export function DeleteWidget(id: string): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(DeleteWidgetState(loading()));
    try {
      const { value } = await api.delete(`/widgets/${id}`);
      dispatch(DeleteWidgetState(success(value)));
    } catch (error) {
      dispatch(DeleteWidgetState(failed(error)));
    }
  };
}
