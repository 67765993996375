/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { ResizeSensor } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useState } from "react";

import { Button } from "common/components";
import Sidebar from "../../containers/Sidebar";
import Topbar from "../../containers/Topbar";
import styles from "./styles.module.sass";


const dispayMenuAsPopupWidth = 1000;

export default function WorkspaceLayout({
  className, children, ...rest
}: WorkspaceLayoutProps) {
  const classes = [styles.workspace, className];

  // state
  const [sidebarAsPopup, showSidebarAsPopup] =
    useState(window.innerWidth < dispayMenuAsPopupWidth);

  const onResize = (entries: ResizeObserverEntry[]) => {
    let displaySidebarAsPopup = false;
    entries.forEach((e) => {
      if (e.contentRect.width < dispayMenuAsPopupWidth) {
        displaySidebarAsPopup = true;
      }
    });
    if (displaySidebarAsPopup !== sidebarAsPopup) {
      showSidebarAsPopup(displaySidebarAsPopup);
    }
  };

  return (
    <ResizeSensor onResize={onResize}>
      <div className={classes.join(" ")} {...rest}>
        <Topbar
          menuButton={!sidebarAsPopup ? undefined :
            <Popover2
              placement="bottom-end"
              interactionKind="click"
              content={<Sidebar />}>
              <Button icon="menu" />
            </Popover2>
          }
        />
        <div className={styles.body}>
          {sidebarAsPopup ? undefined :
            <Sidebar className={styles.sidebar} />
          }
          <div className={styles.content}>
            {children}
          </div>
        </div>
      </div>
    </ResizeSensor >
  );
}

export type WorkspaceLayoutProps = React.ComponentPropsWithRef<"div"> & {
}