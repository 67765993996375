/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useParams } from 'react-router-dom';

import { useGetAllBmiSubmissions } from 'bmi-submissions/bmi-submission.hooks';
import BmiSubmissionForm from 'bmi-submissions/components/BmiSubmissionForm';
import {
  BackActivityIndicator, BackAsyncError,
  Card, CardContent,
  CardHeader,
  Grid, PageContent, PageHeader, PageNotFound
} from 'common/components';
import { AsyncStateBuilder } from 'common/utils';

export default function BmiSubmissionPage() {
  const { id } = useParams();

  // store
  const { getAllBmiSubmissionsState, getAllBmiSubmissions, submission } = useGetAllBmiSubmissions({
    find: item => item.id === id,
  });

  return (
    <>
      <PageHeader
        title={submission?.email || ''}
        backButtonPath={"/bmi-submissions"}>
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder state={getAllBmiSubmissionsState}
          onLoading={() => <BackActivityIndicator tall />}
          onFailed={(error) => <BackAsyncError error={error} onTryAgain={getAllBmiSubmissions} />}
          onValue={() => {
            if (!submission)
              return <PageNotFound />
            return (
              <Grid md={2} xs={1} gap={20}>
                <Card>
                  <CardHeader title={"Personal Information"}/>
                  <CardContent>
                    <BmiSubmissionForm
                      value={submission}
                      readOnly={true}
                      onChange={() => null}
                      visibleFields={[
                        "gender",
                        "firstName",
                        "lastName",
                        "email",
                        "phone",
                      ]}
                    />
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader title={"Submission Details"}/>
                  <CardContent>
                    <BmiSubmissionForm
                      value={submission}
                      readOnly={true}
                      onChange={() => null}
                      hiddenFields={[
                        "gender",
                        "firstName",
                        "lastName",
                        "email",
                        "phone",
                      ]}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )
          }}
        />
      </PageContent>
    </>
  );
}
