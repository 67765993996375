/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Alert, AlertProps, Icon, IconName, InputGroup, MaybeElement } from '@blueprintjs/core';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { Button } from 'common/components';
import { Row } from '../../flex';
import styles from "./styles.module.sass";


export default function PageHeader({
  className, hidden, children, icon, title,
  backButtonPath, backButtonConfirmProps,
  onSearchQueryChange, callouts = [], ...rest
}: PageHeaderProps) {
  if (hidden) return null;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [expandedCallouts, expandCallouts] = useState<JSX.Element[]>([]);

  const classes = [styles.pageheader, className].join(" ");
  const titleText = _.isArray(title) ? t(title[0], title[2], title[1]) : title;

  const [goBackConfirmProps, setGoBackConfirmProps] =
    useState(null as GoBackConfirmProps | null);

  const goBack = useCallback((confirmProps?: GoBackConfirmProps | null) => {
    if (confirmProps) {
      setGoBackConfirmProps(confirmProps);
    } else {
      navigate(backButtonPath!);
    }
  }, [backButtonPath]);

  const observedRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (observedRef.current) {
      const observer = new IntersectionObserver(([e]) => {
        e.target.toggleAttribute('stuck', e.intersectionRatio < 1);
      }, { threshold: [1] });
      observer.observe(observedRef.current);
    }
  }, [observedRef])

  return (
    <>
      <Row id="page-header" innerRef={observedRef} className={classes} {...rest}>
        {backButtonPath && (
          <Button
            className='back'
            minimal
            icon="chevron-left"
            onClick={() => goBack(backButtonConfirmProps)} />
        )}
        {icon && <div className={styles.icon}><Icon icon={icon} /></div>}
        <h1>{titleText}</h1>
        {onSearchQueryChange ? (
          <InputGroup
            type='search'
            leftIcon='search'
            className={styles.searchInput}
            placeholder="..."
            onChange={({ target: { value } }) => onSearchQueryChange(value.toLowerCase())}
          />
        ) : <div className='searchInputPlaceholder' />}
        {callouts
          .filter((callout) => expandedCallouts.includes(callout) === false)
          .map((callout) => (
            <div
              key={`calloutIcon_${callout.key}`}
              className={styles.calloutIcon}
              onClick={() => expandCallouts([...expandedCallouts, callout])} >
              <Icon
                icon={callout.props.icon}
                intent={callout.props.intent}
              />
            </div>
          ))}
        {children}
        <Alert
          confirmButtonText='Yes'
          cancelButtonText='Nope'
          intent="danger"
          isOpen={goBackConfirmProps != null}
          onClose={() => setGoBackConfirmProps(null)}
          onConfirm={() => goBack()}
          {...goBackConfirmProps}>
          <p>{goBackConfirmProps?.message}</p>
        </Alert>
      </Row>
      <div className={styles.pageHeaderCallouts}>
        {callouts.map((callout) => (
          <div
            key={`calloutWrapper_${callout.key}`}
            className={[
              styles.calloutWrapper,
              expandedCallouts.includes(callout) ? undefined : "collapsed"
            ].join(" ")}>
            {callout}
          </div>
        ))}
      </div>
    </>
  );
}

export type GoBackConfirmProps = {
  message: string,
} & Omit<AlertProps, "isOpen" | "onClose" | "onConfirm">;

export type PageHeaderProps = {
  className?: string,
  hidden?: boolean,
  children?: React.ReactNode,
  icon?: IconName | MaybeElement,
  title: string | [string, object?, string?],
  backButtonPath?: string | null,
  backButtonConfirmProps?: GoBackConfirmProps | null,
  onSearchQueryChange?: (query: string) => void,
  callouts?: JSX.Element[],
}