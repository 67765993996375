/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button, Card, CardContent,
  CardHeader, Column, Grid, PageContent, PageHeader
} from 'common/components';
import {
  isLoading, isSuccessful, useAppState,
  useFormData, useInitial, useStateErrors
} from 'common/utils';
import { CreateAdaWidget } from 'widget/ada/actions/create-ada-widget.action';
import { AdaWidget } from 'widget/ada/ada-widget.entities';
import AdaWidgetForm from 'widget/ada/components/AdaWidgetForm';
import AdaWidgetProfilesForm from 'widget/ada/components/AdaWidgetProfilesForm';
import AdaWidgetStylesForm from 'widget/ada/components/AdaWidgetStylesForm';


export default function () {
  const initial = useInitial();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // store
  const [saveState, dispatch] = useAppState((store) => store.widget.save);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<AdaWidget>(null, () => ({
      type: 'ada',
      active: true,
      name: 'ADA Widget',
      domain: '',
    }), []);
  useStateErrors(saveState, setFormErrors);

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(saveState)) {
        navigate(pathname.replace("/new", `/${saveState.value!.id}`), { replace: true });
      }
    }
  }, [saveState]);

  return (
    <>
      <PageHeader
        title={"New ADA Widget"}
        backButtonPath="/widgets">
        <Button
          text="Save"
          intent="success"
          onClick={() => dispatch(CreateAdaWidget(formData))}
          loading={isLoading(saveState)}
        />
      </PageHeader>
      <PageContent>
        <Grid md={2} xs={1} gap={20}>
          <Column gap={20}>
            <Card>
              <CardHeader title={["General"]} />
              <CardContent>
                <AdaWidgetForm
                  value={formData}
                  errors={formErrors}
                  readOnly={isLoading(saveState)}
                  onChange={onChange}
                />
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={["Profiles"]} />
              <CardContent>
                <AdaWidgetProfilesForm
                  name='profiles'
                  value={formData?.profiles}
                  errors={formErrors?.profiles}
                  readOnly={isLoading(saveState)}
                  onChange={onChange}
                />
              </CardContent>
            </Card>
          </Column>
          <Card>
            <CardHeader title={["Apperance"]} />
            <CardContent>
              <AdaWidgetStylesForm
                name='styles'
                value={formData?.styles}
                errors={formErrors?.styles}
                readOnly={isLoading(saveState)}
                onChange={onChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </PageContent>
    </>
  );
}
