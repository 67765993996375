/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";
import * as Blueprint from "@blueprintjs/core";
import { TFunction } from "react-i18next";

import { ApiError } from "common/utils";

import styles from "./styles.module.sass";


class ToasterWrapper {
  private toaster: Blueprint.IToaster;

  constructor() {
    this.toaster = Blueprint.Toaster.create({
      className: styles.toaster,
      maxToasts: 1,
    });
  }

  public showAsyncError = (t: TFunction<"translation", undefined>, error: ApiError, { ...rest } = {}) => {
    const params = _.omit(error, "code", "message");
    const message = t(`ApiErrors.${error.code}`, error.code, params);
    const icon = error.code === "http-error" || error.code ==="ws-error"
      ? "offline" : "warning-sign";
    this.toaster.show({
      icon,
      intent: "danger",
      message,
      ...rest,
    });
  };

  public showSuccess = (props: Blueprint.IToastProps, key?: string | undefined) => {
    this.toaster.show({
      icon: "confirm",
      intent: "success",
      ...props,
    }, key);
  };

  public showInfo = (props: Blueprint.IToastProps, key?: string | undefined) => {
    this.toaster.show({
      icon: "confirm",
      intent: "primary",
      ...props,
    }, key);
  };

  public showDanger = (props: Blueprint.IToastProps, key?: string | undefined) => {
    this.toaster.show({
      icon: "confirm",
      intent: "danger",
      ...props,
    }, key);
  };
}

const Toast = new ToasterWrapper();

export default Toast;
