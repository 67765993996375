/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { useNavigate } from "react-router-dom";

import DomainCard from 'domain/components/DomainCard';
import {
  BackAsyncError, Backout, Button, Grid, GridContentLoader,
} from "common/components";
import { AsyncStateBuilder } from "common/utils";
import { Domain } from '../../domain.entities';
import { useGetAllDomains } from "../../domain.hooks";


export default function DomainsGrid({ filterQuery }: DomainsGridProps) {
  const navigate = useNavigate();
  const { getAllDomainsState, getAllDomains, domains } = useGetAllDomains({
    filter: (domain) =>
      _.isEmpty(filterQuery) || _.values(domain).join(" ").toLowerCase().includes(filterQuery!),
  });

  return (
    <AsyncStateBuilder<Domain[]>
      state={getAllDomainsState}
      onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
      onFailed={(error) => <BackAsyncError error={error} onTryAgain={getAllDomains} />}
      onValueEmpty={() => (
        <div style={{ marginTop: 150 }}>
          <Backout
            icon="globe"
            title="No Domains Yet">
            <div style={{ marginTop: 20 }}>
              <Button
                outlined
                text={["Connect"]}
                onClick={() => navigate("/domains/new")} />
            </div>
          </Backout>
        </div>
      )}
      onValueNotEmpty={() =>
        <Grid md={3} sm={2} xs={1} gap={20} >
          {domains.map(domain =>
            <DomainCard
              key={domain.id}
              value={domain}
              onClick={() => navigate(domain.id!)}
            />
          )}
        </Grid>
      }
    />
  );
}

export type DomainsGridProps = {
  filterQuery?: string | null;
}
