/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Profile } from "profile/profile.entities";
import { AsyncAction, failed, loading, success } from "common/utils";

import { LoginState } from "auth/auth.store";
import { SaveProfileState } from "../profile.store";


export function UpdateProfile(id: string, changes: Partial<Profile>): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(SaveProfileState(loading()));
    try {
      const { value } = await api.put(`/profiles/${id}`, {
        data: changes,
      })
      dispatch(SaveProfileState(success(value)));
      // update login state
      const identity = store().auth.login.value!;
      dispatch(LoginState(success({ ...identity, profile: value })));
    } catch (error) {
      dispatch(SaveProfileState(failed(error)));
    }
  };
}
