/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Dialog, DialogProps } from '@blueprintjs/core';
import { useMemo } from 'react';

import { ClipboardButton } from 'common/components';
import { getApiUrl } from 'common/utils';
import { BaseWidget } from 'widget/widget.entities';
import styles from './styles.module.scss';

export default function PublishDialog({
  className, widget, placeholderHtml, ...rest
}: PublishDialogProps) {
  const classes = useMemo(() => {
    const cls = [styles.publishDialog, className];
    return cls.join(" ").trim();
  }, [className])

  return (
    <Dialog
      className={classes}
      title={"Code Snippet"}
      usePortal={false}
      isCloseButtonShown={true}
      {...rest}>
      <div className='box'>
        <code>
          {placeholderHtml == null ? null : (
            <>
              {placeholderHtml}
              <br /><br />
            </>
          )}
          {'<script'}<br />
          &nbsp;{`src="${getApiUrl()}/widgets/${widget.type}/${widget.id}-v${widget.version}.js">`}<br />
          {'</script>'}
        </code>
        <ClipboardButton
          intent='warning'
          textToCopy={
            (placeholderHtml == null ? '' : `${placeholderHtml}\n\n`) +
            `<script src="${getApiUrl()}/widgets/${widget.type}/${widget.id}-v${widget.version}.js"></script>`
          } />
      </div>
      <p>Copy and paste this code into <code>{`<body>`}</code> section of your website</p>
    </Dialog>
  );
}

export type PublishDialogProps = DialogProps & {
  className?: string;
  widget: BaseWidget;
  placeholderHtml?: string;
}