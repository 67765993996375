/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@blueprintjs/core";

import { Button } from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import DomainsGrid from "domain/containers/DomainsGrid";
import { useGetAllDomains } from "domain/domain.hooks";


export default function DomainsPage() {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const { getAllDomains } = useGetAllDomains();
  return (
    <>
      <PageHeader
        icon={<Icon icon="globe" size={20} style={{ marginRight: 2, marginTop: -.5 }} />}
        title={['My Domains']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text="Connect"
          intent="success"
          onClick={() => navigate('new')}
        />
        <Button
          minimal
          icon="refresh"
          onClick={getAllDomains}
        />
      </PageHeader>
      <PageContent>
        <DomainsGrid filterQuery={searchQuery} />
      </PageContent>
    </>
  );
}
