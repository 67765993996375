/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { RiBodyScanFill } from "react-icons/ri";
import { useMemo } from 'react';

import { BmiSubmission } from "bmi-submissions/bmi-submission.entities";
import { Column } from "common/components";
import Card, { CardProps } from "common/components/cards/Card";
import styles from "./styles.module.scss";


export default function BmiSubmissionCard({
  className, value, onClick, children, ...rest
}: BmiSubmissionCardProps) {
  const classes = useMemo(() => [styles.bmisubmissionCard, className].join(" "), [className]);

  return (
    <Card
      interactive={onClick != null}
      className={classes}
      onClick={onClick}
      {...rest}>
      <div className={styles.bmisubmissionIcon}>
        <RiBodyScanFill color={'#bbb'} />
      </div>
      <Column fill>
        <h4>{value.firstName} {value.lastName}</h4>
        <footer>{value.email}</footer>
        {children}
      </Column>
    </Card>
  );
}

export type BmiSubmissionCardProps = CardProps & {
  value: BmiSubmission,
};