/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createSlice } from "@reduxjs/toolkit";

import { BmiSubmission } from "bmi-submissions/bmi-submission.entities";
import { AsyncState } from "common/utils/async-state";


export const BmiSubmissionStore = createSlice({
  name: "bmi-submissions",
  initialState: {
    getAll: {} as AsyncState<BmiSubmission[]>,
  },
  reducers: {
    GetAllBmiSubmissionsState: (
      state,
      action: { payload: AsyncState<BmiSubmission[]> },
    ) => {
      state.getAll = action.payload;
    },
  },
});

export const {
  GetAllBmiSubmissionsState,
} = BmiSubmissionStore.actions;

export const { reducer: bmiSubmissionReducer } = BmiSubmissionStore;

export type BmiSubmissionState = ReturnType<typeof BmiSubmissionStore.getInitialState>
