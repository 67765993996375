/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Spinner, SpinnerProps } from "@blueprintjs/core";

import styles from "./styles.module.css";
import { useMemo } from "react";


export default function BackActivityIndicator({
  className, hidden, height, fadeInAnimation = true, size = 40, tall, ...rest
}: BackActivityIndicatorProps) {
  if (hidden) return null;

  const classes = useMemo(() => {
    const classes = [styles.backActivityIndicator, className];
    if (fadeInAnimation) classes.push(styles.fadeInAnimation)
    if (tall) classes.push(styles.tall);
    return classes.join(' ').trim();
  }, [className, tall]);

  return (
    <Spinner
      className={classes}
      size={size}
      style={{ height }}
      {...rest} />
  );
}

export type BackActivityIndicatorProps = SpinnerProps & {
  hidden?: boolean,
  height?: number,
  size?: number,
  tall?: boolean,
  fadeInAnimation?: boolean,
}