/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import styles from "./styles.module.sass";


export default function Center({
  className, children, ...rest
}: CenterProps) {
  return (
    <div className={[styles.center, className].join(" ")} {...rest}>
      {children}
    </div>
  );
}
export type CenterProps = React.ComponentPropsWithRef<"div">