/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { isEmpty, useAppState } from "common/utils";
import { GetAllWidgets } from "widget/common/actions/get-all-widgets.action";
import { BaseWidget } from "widget/widget.entities";


export function useGetAllWidgets<T extends BaseWidget = BaseWidget>(opts: {
  auto?: boolean,
  find?: (value: BaseWidget, index: number, array: BaseWidget[]) => boolean,
  filter?: (value: BaseWidget, index: number, array: BaseWidget[]) => boolean,
} = {}) {
  const [getAllWidgetsState, dispatch] = useAppState((state) => state.widget.getAll);

  const getAllWidgets = useCallback(() => {
    dispatch(GetAllWidgets());
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(getAllWidgetsState))
      getAllWidgets();
  }, [opts.auto, getAllWidgetsState])

  const widgets = useMemo(() =>
    ((opts.filter == null
      ? getAllWidgetsState.value
      : getAllWidgetsState.value?.filter(opts.filter)) || []) as T[],
    [getAllWidgetsState, opts.filter]);

  const widget = useMemo(() =>
    opts.find == null
      ? null
      : getAllWidgetsState.value?.find(opts.find) as T,
    [getAllWidgetsState, opts.find]);

  return { getAllWidgetsState, getAllWidgets, widgets, widget };
}