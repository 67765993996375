/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";

import { ColorPickerField, SwitchField, TextAreaField, TextInputField } from "common/components";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { BmiCalculatorWidget } from "widget/bmi-calculator/bmi-calculator.entities";
import BaseWidgetForm, { BaseWidgetFormProps } from "widget/common/components/BaseWidgetForm";
import styles from "./styles.module.sass";


export default function ({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: BmiCalculatorWidgetFormProps) {
  const classes = useMemo(() => [styles.BmiCalculatorForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  return (
    <BaseWidgetForm<BmiCalculatorWidget>
      className={classes}
      value={value}
      errors={errors}
      readOnly={readOnly}
      onChange={onChange}
      {...rest}>
      <TextInputField
        name={fieldName("logoUrl")}
        label={["Logo URL"]}
        value={value?.logoUrl}
        error={errors?.logoUrl}
        readOnly={readOnly}
        hidden={isHidden("logoUrl")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("accentColor")}
        label="Accent Color"
        value={value?.accentColor}
        error={errors?.accentColor}
        readOnly={readOnly}
        hidden={isHidden("accentColor")}
        onChange={onChange}
      />
      <ColorPickerField
        name={fieldName("ctaButtonColor")}
        label="CTA Button Color"
        value={value?.ctaButtonColor}
        error={errors?.ctaButtonColor}
        readOnly={readOnly}
        hidden={isHidden("ctaButtonColor")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("headingText")}
        label={["Result Heading"]}
        value={value?.headingText}
        error={errors?.headingText}
        readOnly={readOnly}
        hidden={isHidden("headingText")}
        onChange={onChange}
      />
      <TextAreaField
        name={fieldName("descriptionMarkdown")}
        label={["Result Description (markdown)"]}
        value={value?.descriptionMarkdown}
        error={errors?.descriptionMarkdown}
        readOnly={readOnly}
        hidden={isHidden("descriptionMarkdown")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("thankYouButtonText")}
        label={["Thank You Button Text"]}
        value={value?.thankYouButtonText}
        error={errors?.thankYouButtonText}
        readOnly={readOnly}
        hidden={isHidden("thankYouButtonText")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("thankYouButtonUrl")}
        label={["Thank You Button Url"]}
        value={value?.thankYouButtonUrl}
        error={errors?.thankYouButtonUrl}
        readOnly={readOnly}
        hidden={isHidden("thankYouButtonUrl")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("popup")}
        label={["Popup"]}
        value={value?.popup}
        error={errors?.popup}
        readOnly={readOnly}
        hidden={isHidden("popup")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("popupButtonText")}
        label={["Popup Button Text"]}
        value={value?.popupButtonText}
        error={errors?.popupButtonText}
        readOnly={readOnly}
        hidden={value?.popup != true || isHidden("popupButtonText")}
        onChange={onChange}
      />
      {children}
    </BaseWidgetForm>
  );
}

export type BmiCalculatorWidgetFormProps = BaseWidgetFormProps<BmiCalculatorWidget> & {
};