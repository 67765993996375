/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Profile } from "profile/profile.entities";
import { AsyncAction, failed, loading, success } from "common/utils";

import { SaveProfileState } from "../profile.store";


export function CreateProfile(profile: Profile): AsyncAction {
  return async (dispatch, _getStore, api) => {
    dispatch(SaveProfileState(loading(profile)));
    try {
      const { value } = await api.post('/profiles', {
        data: profile,
      })
      dispatch(SaveProfileState(success(value)));
    } catch (error) {
      dispatch(SaveProfileState(failed(error)));
    }
  };
}
