/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";

import { DropdownField, TextAreaField, TextInputField } from "common/components";
import { useFieldName, useIsFieldHidden } from "common/utils";
import BaseWidgetForm, { BaseWidgetFormProps } from "widget/common/components/BaseWidgetForm";
import { AdaWidget, adaWidgetLanguages, adaWidgetButtonPositions, adaWidgetTemplates } from "widget/ada/ada-widget.entities";
import styles from "./styles.module.sass";
import { useTranslation } from "react-i18next";


export default function AdaWidgetForm({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: AdaWidgetFormProps) {
  const { t } = useTranslation();
  const classes = useMemo(() => [styles.adaWidgetForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  const languageOptions = useMemo(() => adaWidgetLanguages.map(i => ({
    id: i,
    text: t(`AdaWidgetLanguages.${i}`),
    value: i,
  })), []);
 
  return (
    <BaseWidgetForm<AdaWidget>
      className={classes}
      value={value}
      errors={errors}
      readOnly={readOnly}
      onChange={onChange}
      {...rest}>
      <DropdownField
        nullable
        name={fieldName("language")}
        label={["Language"]}
        options={languageOptions}
        value={value?.language}
        error={errors?.language}
        readOnly={readOnly}
        hidden={isHidden("language")}
        onChange={onChange}
      />
      {children}
    </BaseWidgetForm>
  );
}

export type AdaWidgetFormProps = BaseWidgetFormProps<AdaWidget> & {
};