/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { PageNotFound } from "common/components/back";


export default function Page404() {
  return (
    <PageNotFound />
  );
}
