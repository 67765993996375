/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { GetAllDomainsState } from '../domain.store';


export function GetAllDomains(): AsyncAction {
  return async (dispatch, _getState, api) => {
    dispatch(GetAllDomainsState(loading()));
    try {
      const { value } = await api.get('/domains');
      dispatch(GetAllDomainsState(success(value)));
    } catch (error) {
      dispatch(GetAllDomainsState(failed(error)));
    }
  };
}