/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Store } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, Method } from "axios";
import { Socket, io } from "socket.io-client";

import { AppState } from 'app.store';
import { Identity } from 'auth/auth.entities';
import { LoginState } from "auth/auth.store";
import { isSuccessful } from "common/utils/async-state";
import { success } from "./async-state";


export const getApiUrl = () => process.env.REACT_APP_API_URL;

class SocketIoWrapper {
  _socket?: Socket;
  _unsubsribeFromReconnectOnNewIdentity?: () => void;

  public disconnect() {
    this._unsubsribeFromReconnectOnNewIdentity?.call(this);
    this._socket?.disconnect();
  }

  public emit(event: string, params?: any) {
    this._socket?.emit(event, params);
  }
}

type Headers = { [header: string]: string };

export type ResponseError = {
  code: string,
  message?: string,
  [key: string]: any,
}

export type Response<V = any> = {
  value?: V,
  error?: ResponseError
  identity?: Identity,
}

export type SocketIoCallback<P = any> = (payload: P) => void;

export interface SocketIoHandlers<P = any> {
  onEvent: {
    data: SocketIoCallback<P[]>,
    insert: SocketIoCallback<P>,
    update: SocketIoCallback<P>,
    delete: SocketIoCallback<P>,
    [key: string]: (payload: P | any) => void,
  };
  onConnected?: (socket: Socket) => void;
  onDisconnected?: (reason?: string) => void;
  onError?: (error: any) => void;
}

export interface SocketIoOptions {
  reconnectOnNewIdentity: boolean, // default: true
  // disconnectOnNoIdentity: boolean, // default: true
}

export class ApiClient {
  private axiosClient: AxiosInstance;
  private refreshTokensPromise?: Promise<Identity>;
  private store?: Store<AppState>;

  constructor() {
    this.axiosClient = axios.create();
    this.axiosClient.interceptors.request.use((config) => {
      config.baseURL = getApiUrl();
      return config;
    }, (error) => Promise.reject(error));
  }

  attachStore(store: Store<AppState>) {
    this.store = store;
    this.axiosClient.interceptors.request.use((config) => {
      _.assign(config.headers, this.authHeaders());
      return config;
    }, (error) => Promise.reject(error));
  }

  private authHeaders(callerHeaders: Headers = {}): Headers {
    const headers: Headers = _.merge({}, callerHeaders);
    if (headers['Authorization'] == null &&
      this.store && isSuccessful(this.store.getState().auth.login)) {
      headers['Authorization'] =
        `Bearer ${this.store.getState().auth.login!.value!.accessToken}`;
    }
    return headers;
  }

  private async postResponse(res: Response): Promise<Response | null> {
    if (!this.store) return null;

    if (res.identity) {
      console.log('API returned new identity: ', res.identity);
      const state = this.store.getState();
      if (isSuccessful(state.auth.login)) {
        this.store.dispatch(LoginState(success(res.identity)));
      }
      return res;
    }
    if (res.error) {
      if (res.error.code === "relogin-required" ||
        res.error.code === "no-refresh-token") {
        console.log('relogin required!');
        this.store.dispatch(LoginState({}));
        throw res.error;
      }
      if (res.error.code === "access-token-expired") {
        console.log('access token expired, refreshing...');
        const state = this.store.getState();
        if (isSuccessful(state.auth.login)) {
          await this.refreshTokens(state.auth.login!.value!.refreshToken);
          return null;
        }
      }
      throw res.error;
    }
    return res;
  }

  async refreshTokens(refreshToken) {
    if (!this.refreshTokensPromise) {
      this.refreshTokensPromise = (async () => {
        const res = await this.get("/auth/identity", {
          params: { refreshToken },
        });
        await this.postResponse(res);
        // waiting new identity to update store
        await new Promise((resolve) => setTimeout(resolve, 500));
        this.refreshTokensPromise = undefined;
        return res.identity!;
      })();
    }
    return this.refreshTokensPromise;
  }

  async get(path: string, config: AxiosRequestConfig = {}) {
    return this.call(path, "get", config);
  }

  async post(path: string, config: AxiosRequestConfig = {}) {
    return this.call(path, "post", config);
  }

  async patch(path: string, config: AxiosRequestConfig = {}) {
    return this.call(path, "patch", config);
  }

  async put(path: string, config: AxiosRequestConfig = {}) {
    return this.call(path, "put", config);
  }

  async delete(path: string, config: AxiosRequestConfig = {}) {
    return this.call(path, "delete", config);
  }

  async call(path: string, method: Method, config: Omit<AxiosRequestConfig, "method"> = {}): Promise<Response> {
    try {
      const res = await this.axiosClient({ url: path, method, ...config });
      return await this.postResponse(res.data) || await this.call(path, method, config);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.data.error) {
          await this.postResponse(axiosError.response!.data);
          return await this.call(path, method, config);
        }
      }
      throw error;
    }
  }

  watch<P = any>(path: string, handlers: SocketIoHandlers<P>, opts?: SocketIoOptions) {
    const ns = path.startsWith('/') ? path : '/' + path;
    const connect = (wrapper: SocketIoWrapper) => {
      wrapper._socket = io(getApiUrl() + ns, {
        autoConnect: false,
        auth: this.authHeaders(),
      });
      wrapper._socket.on("connect", () => {
        console.log(`io:${path} connected`);
        handlers.onConnected?.call(handlers, wrapper._socket!);
      });
      wrapper._socket.on("disconnect", (reason) => {
        console.log(`io:${path} disconnected: ${reason}`);
        handlers.onDisconnected?.call(handlers, reason);
      });
      wrapper._socket.on('reconnecting', (attempt) => {
        console.log(`io:${path} reconnecting (${attempt})`);
      });
      wrapper._socket.on('connect_error', (error) => {
        console.log(`io:${path} connect_error: ${error}`);
        handlers.onError?.call(handlers, error);
      });
      wrapper._socket.on('error', (error) => {
        console.log(`io:${path} error: `, error);
        this.postResponse({ error })
          .then(_res => {
            // wrapper._socket?.disconnect();
            // connect(wrapper);
          })
          .catch(error => handlers.onError?.call(handlers, error))
      });
      wrapper._socket.on('identity', (identity) => {
        this.postResponse({ identity });
      });
      _.forEach(_.entries(handlers.onEvent), ([eventType, handler]) => {
        wrapper._socket!.on(eventType, (data) => {
          console.log(`io:${path} ${eventType}: `, data);
          this.postResponse(data).then(handler as any);
        });
      });

      if (this.store && opts?.reconnectOnNewIdentity !== false) {
        const currentIdentity = this.store.getState().auth.login.value?.accessToken;
        wrapper._unsubsribeFromReconnectOnNewIdentity = this.store.subscribe(() => {
          if (currentIdentity != this.store!.getState().auth.login.value?.accessToken) {
            console.log(`identity has changed, reconnecting ${ns}`)
            wrapper.disconnect();
            connect(wrapper);
          }
        })
      }

      wrapper._socket!.connect();
    }
    const wrapper = new SocketIoWrapper();
    connect(wrapper);
    return wrapper;
  }
}
