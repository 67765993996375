/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { LoginState } from "auth/auth.store";
import { AsyncAction, failed, loading, success } from "common/utils";


export function LoginWithPincode(token: string, pincode: string): AsyncAction {
  return async (dispatch, _getState, api) => {
    dispatch(LoginState(loading()));
    try {
      const { identity } = await api.get("/auth/pincode", {
        params: { token, pincode }
      });
      dispatch(LoginState(success(identity!)));
    } catch (error) {
      dispatch(LoginState(failed(error)));
    }
  };
}