/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { AppState } from 'app.store';
import { EntityFormProps } from 'common/components/form/Form';
import Toast from 'common/components/toast';
import { AsyncState, isFailed } from 'common/utils/async-state';
import { deepDiffs } from 'common/utils/objects';
import { OnChangeEventHandler } from 'common/utils/types';


// TODO: ability to substitude app state
// e.g. to replace component behaviour, instead of saving via API
// it would change the data localy (for new entities)

export function useAppState<S>(selector: (state: AppState) => S): [S, Dispatch<any>] {
  const dispatch = useDispatch();
  const state = useSelector(selector);
  return [state, dispatch];
}

export function useInitial() {
  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);
  return firstRender.current;
}

export function useStateErrors<S = any>(state: AsyncState<S>, setFormErrors: React.Dispatch<React.SetStateAction<any>>) {
  const { t } = useTranslation();
  const initial = useInitial();
  useEffect(() => {
    if (!initial) {
      if (isFailed(state)) {
        if (state.error?.code === 'constraint-error') {
          const fn = (value: any) => {
            if (_.isArray(value)) {
              return [`Constraints.${value[0]}`, value[1] || {}];
            } else if (_.isObject(value)) {
              const obj = {};
              for (const prop of Object.getOwnPropertyNames(value))
                obj[prop] = fn(value[prop]);
              return obj;
            } else {
              console.error('invalid constraint value', value);
            }
          }
          setFormErrors(_.transform(state.error!.constraints,
            (result, value, key) => {
              result[key] = fn(value);
            }, {}));
        } else {
          Toast.showAsyncError(t, state.error!);
        }
      }
    }
  }, [state]);
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export function useFormData<V extends object>(origin: V | null | undefined, defaults: V | (() => V) | null | undefined, deps?: any[]) {
  if (origin == null && defaults == null)
    throw new Error("Both, origin and defaults cannot be nulls");

  const orDefaults = useCallback(() => _.isFunction(defaults) ? defaults() : defaults, [defaults])
  const [formData, setFormData] = useState(() => origin || orDefaults()!);
  const [formErrors, setFormErrors] = useState({} as { [K in keyof V]: any });
  const changes = useMemo<Partial<V>>(() => deepDiffs(formData, origin || {}), [origin, formData])

  useEffect(() => setFormData(origin || orDefaults()!), deps)
  const onChange: OnChangeEventHandler<unknown> = ({ target: { name, value } }) => {
    if (_.get(formErrors, name)) {
      setFormErrors(_.omit(formErrors, name) as any);
    }
    const data = _.cloneDeep(formData);
    _.set(data, name, value)
    setFormData(data);
  };

  return {
    formData,
    setFormData,
    changes,
    formErrors,
    setFormErrors,
    onChange,
  }
}

export function useFieldName(formProps: Pick<EntityFormProps<any>, "name">) {
  return useCallback((fieldName) =>
    _.isEmpty(formProps.name) ? fieldName : (formProps.name + '.' + fieldName),
    [formProps.name]);
}

export function useIsFieldHidden(formProps: Pick<EntityFormProps<any>, "name" | "visibleFields" | "hiddenFields">) {
  const fullName = useFieldName(formProps);
  return useCallback((shortName) =>
    formProps.visibleFields?.includes(fullName(shortName)) === false ||
    formProps.hiddenFields?.includes(fullName(shortName)) === true,
    [formProps.visibleFields, formProps.hiddenFields]);
}

export function useIsReadOnlyField(formProps: Pick<EntityFormProps<any>, "name" | "readOnlyFields">) {
  const fullName = useFieldName(formProps);
  return useCallback((shortName) =>
    formProps.readOnlyFields?.includes(fullName(shortName)) === true,
    [formProps.readOnlyFields]);
}