/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { RiBodyScanFill } from "react-icons/ri";

import BmiSubmissionCard from 'bmi-submissions/components/BmiSubmissionCard';
import {
  BackAsyncError, Backout, Button, Grid, GridContentLoader,
} from "common/components";
import { AsyncStateBuilder } from "common/utils";
import { BmiSubmission } from '../../bmi-submission.entities';
import { useGetAllBmiSubmissions } from "../../bmi-submission.hooks";


export default function BmiSubmissionsGrid({ filterQuery }: BmiSubmissionsGridProps) {
  const navigate = useNavigate();
  const { getAllBmiSubmissionsState, getAllBmiSubmissions, submissions } = useGetAllBmiSubmissions({
    filter: (item) =>
      _.isEmpty(filterQuery) || _.values(item).join(" ").toLowerCase().includes(filterQuery!),
  });

  return (
    <AsyncStateBuilder<BmiSubmission[]>
      state={getAllBmiSubmissionsState}
      onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
      onFailed={(error) => <BackAsyncError error={error} onTryAgain={getAllBmiSubmissions} />}
      onValueEmpty={() => (
        <div style={{ marginTop: 150 }}>
          <Backout
            icon={<RiBodyScanFill />}
            title="No BMI Submissions Yet">
          </Backout>
        </div>
      )}
      onValueNotEmpty={() =>
        <Grid md={3} sm={2} xs={1} gap={20} >
          {submissions.map(bmisubmission =>
            <BmiSubmissionCard
              key={bmisubmission.id}
              value={bmisubmission}
              onClick={() => navigate(bmisubmission.id!)}
            />
          )}
        </Grid>
      }
    />
  );
}

export type BmiSubmissionsGridProps = {
  filterQuery?: string | null;
}
