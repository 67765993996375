/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";

import { LoginStrategiesState } from "../auth.store";


export function LoadLoginStrategies(email: string, callbackUrl: string): AsyncAction {
  return async (dispatch, _getStore, api) => {
    dispatch(LoginStrategiesState(loading()));
    try {
      const { value } = await api.get("/auth/strategies", {
        params: {
          email: email,
          callbackUrl,
          apiKey: process.env.REACT_APP_API_KEY,
          asProfile: true,
        },
      });
      dispatch(LoginStrategiesState(success(value)));
    } catch (error) {
      dispatch(LoginStrategiesState(failed(error)));
    }
  };
}
