/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createSlice } from "@reduxjs/toolkit";

import { AsyncState, isSuccessful } from "common/utils/async-state";
import { Identity, LoginStrategy } from "./auth.entities";


export const AuthStore = createSlice({
  name: "auth",
  initialState: {
    login: JSON.parse(localStorage.getItem("loginState") ?? "{}") as AsyncState<Identity>,
    loginStrategies: {} as AsyncState<LoginStrategy[]>,
    pincode: {
      save: {} as AsyncState<Identity>,
      delete: {} as AsyncState<Identity>,
    }
  },
  reducers: {
    LoginStrategiesState: (state, action) => {
      state.loginStrategies = action.payload;
    },
    LoginState: (state, action: { payload: AsyncState<Identity> }) => {
      if (isSuccessful(action.payload)) {
        localStorage.setItem("loginState", JSON.stringify(action.payload));
        state.loginStrategies = {};
      } else {
        localStorage.removeItem("loginState");
      }
      state.login = action.payload;
    },
    // Pincode
    SavePincodeState: (state, action) => {
      state.pincode.save = action.payload;
    },
    DeletePincodeState: (state, action) => {
      state.pincode.delete = action.payload;
    },
  },
});

export const {
  LoginState,
  LoginStrategiesState,
  SavePincodeState,
  DeletePincodeState,
} = AuthStore.actions;

export const { reducer: authReducer } = AuthStore;
export type AuthState = ReturnType<typeof AuthStore.getInitialState>