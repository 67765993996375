/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createSlice } from "@reduxjs/toolkit";

import { AsyncState } from "common/utils/async-state";
import { Subscription } from "subscription/subscription.entities";


export const SubscriptionStore = createSlice({
  name: "subscription",
  initialState: {
    getCustomerPortalLink: {} as AsyncState<string>,
    getCurrentSubscription: {} as AsyncState<Subscription>,
  },
  reducers: {
    GetCustomerPortalLinkState: (
      state,
      action: { payload: AsyncState<string> },
    ) => {
      state.getCustomerPortalLink = action.payload;
    },
    GetCurrentSubscriptionState: (
      state,
      action: { payload: AsyncState<Subscription> },
    ) => {
      state.getCurrentSubscription = action.payload;
    },
  },
});

export const {
  GetCustomerPortalLinkState,
  GetCurrentSubscriptionState,
} = SubscriptionStore.actions;

export const { reducer: subscriptionReducer } = SubscriptionStore;

export type SubscriptionState = ReturnType<typeof SubscriptionStore.getInitialState>
