/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "app.store";
import { Button } from "common/components";
import { DropdownField, Form, FormButton, TextInputField } from "common/components/form";
import { FormProps } from "common/components/form/Form";
import FormTextInput from "common/components/form/TextInput";
import Toast from "common/components/toast";
import { LoginWithPincode } from "auth/actions/pincode/login-with-pincode.action";
import { isFailed, isLoading, isSuccessful, useInitial, useStateErrors } from "common/utils";
import { LoadLoginStrategies } from "../../actions/get-login-strategies.action";

import styles from "./styles.module.sass";


export default function ({ className, ...formProps }: LoginFormProps) {
  const { t } = useTranslation();
  const classes = [styles.container, className].join(" ");
  const initial = useInitial();
  const dispatch = useDispatch();

  // state
  const [formData, setFormData] = useState({} as any);
  const [formErrors, setFormErrors] = useState({} as any);
  const [selectedProfileId, setSelectedProfileId] = useState<String | undefined>(undefined);

  // store
  const loginStrategiesState = useSelector((state: AppState) => state.auth.loginStrategies);
  const loginState = useSelector((state: AppState) => state.auth.login);

  useStateErrors(loginStrategiesState, setFormErrors);
  useStateErrors(loginState, setFormErrors);

  // memos
  const loading = useMemo(() =>
    isLoading(loginState) || isLoading(loginStrategiesState),
    [loginState, loginStrategiesState]);
  const loginStrategies = useMemo(() => {
    if (isSuccessful(loginStrategiesState)) {
      return loginStrategiesState.value;
    }
    return [];
  }, [loginStrategiesState])
  const profiles = useMemo(() => {
    const profiles: Array<{ name: string, id: string | undefined }> = [];
    for (const strategy of loginStrategies || []) {
      if (strategy.profile && profiles.find(b => b.id === strategy.profile!.id) == null) {
        profiles.push(strategy.profile);
      }
    }
    if (profiles.length > 0 && selectedProfileId == null)
      setSelectedProfileId(profiles[0].id);
    profiles.push({ id: undefined, name: '...new' });
    return profiles;
  }, [loginStrategies])

  // callbacks
  const loadLoginStrategies = useCallback(() => {
    _.invoke(document.activeElement, 'blur');
    dispatch(LoadLoginStrategies(formData.email,
      `${window.location.protocol}//${window.location.hostname}` +
      `:${window.location.port}/on-login`));
  }, [formData]);

  const onChange = useCallback(({ target: { name, value } }) => {
    if (_.get(formErrors, name)) {
      setFormErrors(_.omit(formErrors, name));
    }
    const data = _.clone(formData);
    _.set(data, name, value)
    setFormData(data);
  }, [formData, formErrors]);

  // effects
  useEffect(() => {
    if (!initial && isFailed(loginState)) {
      if (loginState.error!.code === 'invalid-pincode') {
        setFormErrors({
          pincode: ['ApiErrors.invalidPincode', {
            count: loginState.error!.attemptsLeft
          }]
        });
      } else {
        Toast.showAsyncError(t, loginState.error!);
      }
    }
  }, [initial, loginState]);

  return (
    <Form className={classes} {...formProps}>
      <TextInputField
        name="email"
        label="Email"
        value={formData.email}
        error={formErrors.email}
        readOnly={loading || !_.isEmpty(loginStrategies)}
        onChange={onChange}
        onSubmit={loadLoginStrategies}
      />
      {_.isEmpty(loginStrategies)
        ? <FormButton outlined
          loading={loading}
          onClick={loadLoginStrategies}
          text="Continue" />
        : <>
          <DropdownField
            label={["Profile"]}
            value={selectedProfileId}
            hidden={true}
            options={profiles.map(b => ({
              id: b.id!,
              value: b.id,
              text: b.name,
            }))}
            onChange={(event) => {
              setSelectedProfileId(event.target.value as any);
            }}
          />
          {loginStrategies!.filter(s => s.profile?.id === selectedProfileId).map(strategy => {
            switch (strategy.type) {
              case "pincode":
                return (
                  <FormTextInput
                    key="pincodeInput"
                    name="pincode"
                    autoFocus
                    label="Pin Code"
                    placeholder={`${strategy.length} digits`}
                    readOnly={loading}
                    value={formData.pincode}
                    error={formErrors.pincode}
                    type="password"
                    maxLength={strategy.length}
                    onChange={(e) => {
                      onChange(e);
                      const value = e.target.value;
                      if (value.length === strategy.length) {
                        dispatch(LoginWithPincode(strategy.token!, value))
                      }
                    }}
                    rightElement={
                      <Button
                        minimal
                        icon="arrow-right"
                        loading={loading}
                        onClick={() => {
                          dispatch(LoginWithPincode(strategy.token!, formData.pincode))
                        }}
                      />}
                  />
                );
              case "google":
                return (
                  <FormButton outlined
                    key="googleButton"
                    className={styles.googleButton}
                    icon={<FcGoogle size={18} />}
                    text="Login with Google"
                    onClick={() => window.open(strategy.url, '_self')}
                  />
                );
              default:
                console.error(`Unhandled login strategy ${strategy.type}`);
            }
          })}
        </>
      }
    </Form>
  );
}

export type LoginFormProps = Omit<FormProps, "onChange"> & {
}