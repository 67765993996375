/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";

import { Form, SwitchField, TextInputField } from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { Domain } from "domain/domain.entities";
import styles from "./styles.module.sass";


export default function DomainForm<T extends Domain>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: DomainFormProps<T>) {
  const classes = useMemo(() => [styles.domainForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  return (
    <Form className={classes} {...rest}>
      <TextInputField
        name={fieldName("name")}
        label={["Name"]}
        value={value?.name}
        error={errors?.name}
        readOnly={readOnly}
        hidden={isHidden("name")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("active")}
        label={["Active"]}
        value={value?.active}
        error={errors?.active}
        readOnly={readOnly}
        hidden={isHidden("active")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("http")}
        label={["HTTP"]}
        value={value?.http}
        error={errors?.http}
        readOnly={readOnly}
        hidden={isHidden("http")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("https")}
        label={["HTTPS"]}
        value={value?.https}
        error={errors?.https}
        readOnly={readOnly}
        hidden={isHidden("https")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("subdomains")}
        label={["Include Subdomains"]}
        value={value?.subdomains}
        error={errors?.subdomains}
        readOnly={readOnly}
        hidden={isHidden("subdomains")}
        onChange={onChange}
      />
      {children}
    </Form>
  );
}

export type DomainFormProps<T extends Domain> = EntityFormProps<T> & {
};