/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Switch, SwitchProps } from "@blueprintjs/core";
import { OnChangeEventHandler } from "common/utils";

import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.sass";
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';


export default function SwitchField({
  className, label, hidden, disabled, error,
  name, value, onChange, ...rest
}: SwitchFieldProps) {
  const { t } = useTranslation();
  const labelText = _.isArray(label) ? t(label[0], label[2], label[1]) : label;
  const classes = useMemo(() => {
    const classes = [styles.switchField, className];
    if (disabled)
      classes.push(styles.disabled)
    return classes.join(' ').trim();
  }, [className]);

  return (
    <FormField
      borderless
      className={classes}
      disabled={disabled}
      hidden={hidden}
      error={error} >
      <Switch
        label={labelText}
        alignIndicator="right"
        disabled={disabled}
        intent={error ? "danger" : undefined}
        checked={value}
        onChange={onChange == null && rest.readOnly ? undefined : ({ target }) => {
          onChange!({ target: { name, value: (target as any).checked } });
        }}
        {...rest}
      />
    </FormField>
  );
}



export type SwitchFieldProps = FormFieldProps & Omit<SwitchProps, "onChange" | "checked" | "label" | "value"> & {
  name: string,
  onChange?: OnChangeEventHandler<number>
  value?: boolean,
}