/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useMemo } from 'react';

import { useIdentity } from 'auth/auth.hooks';
import styles from "./styles.module.scss";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export default function ({
  className,
}: SubscriptionPlansProps) {
  const identity = useIdentity();
  const classes = useMemo(() => [styles.subscriptionPlans, className].join(" "), [className]);

  return (
    <stripe-pricing-table
      className={classes}
      pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
      publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
      client-reference-id={identity!.profile!.id}
    />
  );
}

export type SubscriptionPlansProps = {
  className?: string;
};